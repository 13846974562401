$(function () {

	$(document).on("click", ".s31-tooltip", function () {
        clearTimeout(closeTimeout);
        if ($(".s31-tooltipBody").hasClass("active")) {
            $(".s31-tooltipBody").removeClass("active")
        }
        $(this).children(".s31-tooltipBody").addClass("active");
        var closeTimeout = setTimeout(function(){ 
            $(".s31-tooltipBody").removeClass("active");
        }, 15000);
    });
    
    $(document).on("click", ".s31-tooltip-ico", function () {
        clearTimeout(closeTimeout);
        $(this).next(".s31-tooltipBody").slideToggle();
        var closeTimeout = setTimeout(function(){ 
            $(".s31-tooltipBody").hide();
        }, 15000);
	});

});