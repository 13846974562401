$(function () {
	$("#s31-article-desriptionshort-more").click(function (event) {
		openTab(event, 's31-article-description');
		$("#s31-detail-tabLinks-popis").addClass("active");
		$([document.documentElement, document.body]).animate({
			scrollTop: $(".s31-detail-tabs").offset().top
		}, 500);
	});

	$("#s31-showAllReviews").click(function () {
		$("#s31-review-hidden").slideToggle();
		$('#s31-showAllReviews').toggleText(detail_pageReviewsShow_more + " (" + detailPageReviewCount + ")", detail_pageReviewsShow_less);
		$([document.documentElement, document.body]).animate({
			scrollTop: $(".s31-review-list").offset().top - 20
		}, 500);
	});

	$(document).on("click", ".s31-show-all-reviews", function (event) {
		openTab(event, 's31-article-review');
		$("#s31-detail-tabLinks-review").addClass("active");
		if (!$("#s31-article-review").hasClass("opened")) {
			$("#s31-article-review").addClass("opened")
			$("#s31-article-description").removeClass("opened")
		}
		$([document.documentElement, document.body]).animate({
			scrollTop: $(".s31-detail-tabs").offset().top
		}, 500);
	});

	$(document).on("click", ".s31-review-popupClose", function () {
		$("#addReviewModal").modal("hide");
		$("#s31-review-form").show();
		$("#s31-review-success").hide();

		$('#star0').click();
		$('[name="sylius_product_review[rating]"]').removeAttr('checked');
	});

	$(document).on("click", ".s31-review-addReview", function () {
		$("#s31-review-form").show();
		$("#s31-review-success").hide();
		$("#sylius_product_review_comment").val("");

		$('#star0').click();
		$('[name="sylius_product_review[rating]"]').removeAttr('checked');

		$("#addReviewModal").modal("show");
	});

	$("form[name='sylius_product_review']").on("submit", function (e) {
		e.preventDefault();

		var $this = $(this);
		var href = "/ajax" + $this.attr('action');

		$this.find(".sylius-validation-error").remove();
		$this.find(".error").removeClass("error");

		$.ajax({
			method: 'POST',
			data: $this.serialize(),
			cache: false,
			url: href,
			success: function (response) {
				$("#s31-review-form").hide();
				$("#s31-review-success").show();
			},
			error: function (jqXHR) {
				if (jqXHR.responseJSON !== "") {
					var response = jqXHR.responseJSON;
					var validationMessage = "";

					if (response.hasOwnProperty('errors')) {

						if (response.errors.hasOwnProperty('errors')) {
							$.each(response.errors.errors, function (key, message) {
								validationMessage += message + "<br>";
							});

							$this.prepend('<div class="alert alert-danger pointing label sylius-validation-error">' + validationMessage + '</div>');
						}

						if (response.errors.hasOwnProperty('children')) {
							processFormInputErrors($this.attr("name"), response.errors.children);
						}
					}
				}
			}
		});

		function processFormInputErrors(prefix, errors) {
			$.each(errors, function (key, row) {
				var actualPrefix = prefix + "[" + key + "]";

				if (row.hasOwnProperty("errors")) {
					for (var i in row.errors) {
						if(key == 'rating'){
							$("[name='" + actualPrefix + "'").addClass("error").removeClass("success");
							$("[name='" + actualPrefix + "'").parent().after('<div class="alert alert-danger pointing label sylius-validation-error">' + row.errors[i] + '</div>');
						}
						else{
							$("[name='" + actualPrefix + "'").addClass("error").removeClass("success");
							$("[name='" + actualPrefix + "'").after('<div class="alert alert-danger pointing label sylius-validation-error">' + row.errors[i] + '</div>');
						}
					}
				}

				if (row.hasOwnProperty("children")) {
					processFormInputErrors(actualPrefix, row.children);
				}
			});
		}
		return false;
	});
	
});
