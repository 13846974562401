function gaTrackingContainer() {}

function gaTrackingEventTrackingNoCallBackPrototype(category, action, label, value) {

    var container = new gaTrackingContainer();
    container.event = 'ga_event';
    container.eventCategory = category;
    container.eventAction = action;
    container.eventLabel = label;
    if (typeof value !== 'undefined') {
        container.eventValue = value;
    }

    return container;

}

function gaTrackingDatalayerPush(obj) {

	// U pushovani novych prom nebo produktu je potreba vzdy obsah ecommerce vyresetovat
	if (typeof obj.event !== 'undefined') {

		if (obj.hasOwnProperty('ecommerce')) {
			dataLayer.push({'ecommerce':undefined});
		}

	}

    dataLayer.push(obj);
}