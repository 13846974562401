// mediaquaries stejne jako v bootstrapu
const mqsm = window.matchMedia('all and (min-width: 576px) and (max-width: 767px)');
const mqmd = window.matchMedia('all and (min-width: 768px) and (max-width: 991px)');
const mqlg = window.matchMedia('all and (min-width: 992px) and (max-width: 1199px)');
const mqxl = window.matchMedia('all and (min-width: 1200px)');

// mediaquaries jen menu
const mqMENUdesktop = window.matchMedia('all and (min-width: 1025px)');
const mqMENUtablet = window.matchMedia('all and (min-width: 993px) and (max-width: 1024px)');
const mqMENUmobile = window.matchMedia('all and (max-width: 992px)');



// promenne pro menu
const headerAnimationDuration = 200;
const headerAnimationDurationMobile = 200;
const headerMenuTimeout = 300;


//Barvy pro JS (stejné jako v CSS):
const colorPrimary = "#ff5a00";


//obecné proměnné
const activeClass = "s31-activeBlock";
const openedClass = "s31-openedByHead";
const backgroundCoverEl = document.getElementById("s31-sub-menu-container-shadow");
const bodyEl = document.getElementById("bodyElement");
let currentCategoryCode;

//hláška do embroidery
var embErrorMessage = '<div class="alert alert-danger pointing label sylius-validation-error">Prosím vložte text výšivky.</div>';
var embErrorMessageSymbol = '<div class="alert alert-danger pointing label sylius-validation-error">Prosím zvolte symbol výšivky.</div>';



// Vlastní Jquery funcke toggleText
$.fn.toggleText = function(t1, t2){
  if (this.text() == t1) {
	  this.text(t2);
  } else { 
	  this.text(t1);
  }
  return this;
};