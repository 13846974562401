var swiperHomepage = new Swiper('.swiper-homepage', {
  // Optional parameters
  loop: false,
  slidesPerView: 1,
  spaceBetween: 0,
  slidesPerGroup: 1,

  breakpoints: {
    320: {
      slidesPerView: 2,
      slidesPerGroup: 1,
    },
    545: {
      slidesPerView: 2,
      slidesPerGroup: 1,
    },
    737: {
      slidesPerView: 3,
      slidesPerGroup: 1,
    },
    961: {
      slidesPerView: 4,
      slidesPerGroup: 2,
    },
    1169: {
      slidesPerView: 5,
      slidesPerGroup: 2,
    },
    1419: {
      slidesPerView: 6,
      slidesPerGroup: 3,
    },
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

});

var swiperCms = new Swiper('.swiper-cms', {
  // Optional parameters
  loop: false,
  slidesPerView: 1,
  spaceBetween: 0,
  slidesPerGroup: 1,

  breakpoints: {
    320: {
      slidesPerView: 2,
      slidesPerGroup: 1,
    },
    545: {
      slidesPerView: 2,
      slidesPerGroup: 1,
    },
    737: {
      slidesPerView: 3,
      slidesPerGroup: 1,
    },
    961: {
      slidesPerView: 3,
      slidesPerGroup: 1,
    },
    1169: {
      slidesPerView: 3,
      slidesPerGroup: 1,
    },
    1419: {
      slidesPerView: 3,
      slidesPerGroup: 1,
    },
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

});

var swiperPopup = new Swiper('.swiper-popup', {
  // Optional parameters
  loop: false,
  slidesPerView: 1,
  spaceBetween: 0,
  slidesPerGroup: 1,

  breakpoints: {
    320: {
      slidesPerView: 2,
      slidesPerGroup: 1,
    },
    545: {
      slidesPerView: 2,
      slidesPerGroup: 1,
    },
    737: {
      slidesPerView: 4,
      slidesPerGroup: 1,
    },
    961: {
      slidesPerView: 4,
      slidesPerGroup: 1,
    },
    1169: {
      slidesPerView: 4,
      slidesPerGroup: 1,
    },
    1419: {
      slidesPerView: 4,
      slidesPerGroup: 1,
    },
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-popup-next',
    prevEl: '.swiper-button-popup-prev',
  },

});

var swiperHomepageBanners = new Swiper ('.swiper-container-homepage', {
  slidesPerView: 1,
  slidesPerGroup: 1,
  loop: true,
  autoplay: {
      delay: 5000,
      disableOnInteraction: false,
  },

  // Navigation arrows
  navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
  }
});

var swiperEmbroidery;