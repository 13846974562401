(function ( $ ) {
    'use strict';

    $.fn.extend({
        apiCheckoutLogin: function (apiSettings) {
            var element = $(this);
            var apiSettings = apiSettings || {};
            var passwordField = element.find('input[type=\'password\']');
            var emailField = element.find('input[type=\'email\']');
            var csrfTokenField = element.find('input[type=\'hidden\']');
            var validationField = passwordField.parent().find('.red.label');
            var checkValidationField = emailField.parent().next('.red.label');
            var passwordRow = element.find(".s31-password-row");
            var loginButton = element.find("#sylius-api-login-submit");
            var checkTimeout = null;

            emailField.on("keydown", function(e) {
                if (e.keyCode == 13) {
                    e.preventDefault();

                    checkEmail(emailField.val(), emailField.data("url"));
                }
            });

            emailField.on("keyup", function(e) {
                clearTimeout(checkTimeout);

                if (e.keyCode == 13) {
                    return;
                }

                checkTimeout = setTimeout(function() {
                    checkEmail(emailField.val(), emailField.data("url"));
                }, 400);
            });

            passwordField.on("keydown", function(e) {
                if (e.keyCode == 13) {
                    e.preventDefault();

                    loginButton.click();
                }
            });

            loginButton.on("click", function(e) {
                e.preventDefault();

                if (emailField.val() == '') {
                    emailField.parent().parent().addClass("error");
                    checkValidationField.removeClass('d-none');
                    passwordRow.addClass("d-none");
                    loginButton.addClass("d-none");
                    checkValidationField.html(loginMessage);
                    $(".s31-address-email-row").removeClass("sucess");
                    $("#s31-checkout-continue-as-guest").text(loginMessageButtonRegistred);
                    return;
                }

                if (passwordRow.is(":hidden")) {
                    checkEmail(emailField.val(), emailField.data("url"));
                } else {
                    var formData = {
                        _username: emailField.val(),
                        _password: passwordField.val()
                    };
                    formData[csrfTokenField.attr('name')] = csrfTokenField.val();

                    $.ajax({
                        method: apiSettings.method || "POST",
                        dataType: apiSettings.dataType || "json",
                        data: formData,
                        cache: false,
                        url: loginButton.data("url"),
                        success: function (response) {
                            passwordField.parent().removeClass("error");
                            element.remove();
                            location.reload();
                        },
                        error: function (jqXHR) {
                            passwordField.parent().addClass("error");
                            validationField.removeClass('d-none');

                            if (jqXHR.status == 401) {
                                validationField.html(loginMessageWrong);
                            } else {
                                validationField.html(jqXHR.responseJSON.message);
                            }
                        }
                    });
                }
            });

            function checkEmail(email, url) {
                emailField.parent().parent().removeClass("error");
                checkValidationField.addClass('d-none');

                if (email == '') {
                    return;
                }

                $.ajax({
                    method: "GET",
                    dataType: "json",
                    data: { email: email },
                    cache: false,
                    url: url,
                    success: function (response) {
                        passwordField.parent().removeClass("error");
                        passwordRow.removeClass("d-none");
                        emailField.parent().parent().removeClass("error");
                        checkValidationField.addClass('d-none');
                        loginButton.removeClass("d-none");
                        $("#s31-checkout-continue-as-guest").text(loginMessageButtonNoRegistred);
                        $(".s31-address-email-row").addClass("sucess");

                        passwordField.focus();
                    },
                    error: function (jqXHR) {
                        checkValidationField.removeClass('d-none');
                        passwordRow.addClass("d-none");
                        loginButton.addClass("d-none");
                        $(".s31-address-email-row").removeClass("sucess");
                        $("#s31-checkout-continue-as-guest").text(loginMessageButtonRegistred);

                        if (jqXHR.status == 404) {
                            checkValidationField.html(loginMessageNoRegistred);
                        } else {
                            emailField.parent().parent().addClass("error");
                            checkValidationField.html(jqXHR.responseJSON.message);
                        }
                    }
                });

                //check flatrate
                $.ajax({
                    method: "GET",
                    dataType: "json",
                    data: { email: email },
                    cache: false,
                    url: "/ajax/customers/flat-rate",
                    success: function (response) {
                        $.ajax({
                            method: "GET",
                            dataType: "html",
                            cache: false,
                            url: "/ajax/checkout/address-summary",
                            success: function (response) {
                                var $payload = $(response);

                                if ($payload.find(".s31-checkout-summary-box").length > 0) {
                                    $(".s31-checkout-summary-box").html($payload.find(".s31-checkout-summary-box").html());
                                }
                            }
                        });
                    }
                });
            }
        }
    });
})( jQuery );