const mobileMenuButtEl = document.getElementById("s31-mobile-menu-button");
const mobileMenuContEl = document.getElementById("s31-mobile-menu-container");

const mobileMenuCloseEl = document.getElementById("mobileSubmenu_headClose");
const mobileMenuTitleEl = document.getElementById("mobileSubmenu_headTitle");
const mobileMenuBackEl = document.getElementById("mobileSubmenu_headBack");
const mobileMenuHomeEl = document.getElementById("mobileMenuPoint_home");
const mobileMenuBodyEl = document.getElementById("s31-mobilemenu-body");
const mobileMenuBackgroundEl = document.getElementById("s31-mobilemenu-back");


const mobileSubmenuCat = document.getElementById("s31-mobilesubmenu-lvl1");
const mobileOpenSubmenus = document.getElementsByClassName("s31-submenu");
const mobileSubmenus = document.getElementsByClassName("s31-mobilemenu-submenu");
const mobileOpenSubmenusLvl2 = document.getElementsByClassName("openMobileSubmenuLvl2");
const mobileSubmenusLvl2 = document.getElementsByClassName("s31-mobilesubmenu-lvl2");

const mobileMenuPointLvl1 = document.getElementsByClassName("s31-mobilemenu-lvl1");
const mobileMenuPointShowNextCategory = document.getElementsByClassName("s31-mobileMenu-openNext");

var scrollDistance;




$(function() {

	if (mqMENUmobile.matches) {
        mobileMenuButtEl.addEventListener("click",  function(){
            scrollDistance = $(document).scrollTop();
            $("html, body").css({"overflow": "hidden", "height": "100%"});
            this.classList.add(activeClass);
            mobileMenuContEl.classList.add(activeClass);
        });
        mobileMenuCloseEl.addEventListener("click",  function(){
            closeMobileMenu();
        });
        mobileMenuBackgroundEl.addEventListener("click",  function(){
            closeMobileMenu();
        });

        mobileMenuBackEl.addEventListener("click",  function(){

            if (this.classList.contains("lvl1")) { 
                this.classList.remove("lvl1");
                mobileMenuTitleEl.innerHTML = "";
                hideAllSubmenusLvl1();
                removeAllactiveLvl1();
                mobileMenuHomeEl.classList.add(activeClass);
                mobileMenuBodyEl.classList.remove(activeClass);
            } else if (this.classList.contains("lvl2")) { 
                this.classList.remove("lvl2");
                this.classList.add("lvl1");
                mobileMenuTitleEl.innerHTML = "Hlavní kategorie";
                hideAllSubmenusLvl2();
            } else {
                closeMobileMenu();
            }

            $(window).scrollTop(scrollDistance);
        });
        
        let openSubmenu = function() {
            let submenuCode = this.getAttribute("data-submenu");
            let submenuHeading = this.getAttribute("data-heading");
            showMobileSubmenuLvl1(submenuCode,submenuHeading);
            mobileMenuBodyEl.classList.add(activeClass);
        }

        for (var i = 0; i < mobileOpenSubmenus.length; i++) {
            mobileOpenSubmenus[i].addEventListener('click', openSubmenu, false);
        }
        
        let openSubmenuLvl2 = function() {
            let submenuCode = this.getAttribute("data-submenu");
            let submenuHeading = this.getAttribute("data-heading");
            showMobileSubmenuLvl2(submenuCode,submenuHeading);
            mobileMenuBodyEl.classList.add(activeClass);
            setMobileMenuLvl1toTop(submenuCode);
        }

        for (var i = 0; i < mobileOpenSubmenusLvl2.length; i++) {
            mobileOpenSubmenusLvl2[i].addEventListener('click', openSubmenuLvl2, false);
        }

        var touchMoveActive = false;
        $(document).on("touchend touchmove", ".s31-mobileMenu-openNext", function (e) {
            if (e.type == "touchmove") {
                touchMoveActive = true;
            }
            if (touchMoveActive == false) {
                $(this).next().slideToggle();
                $(this).find(".s31-mobilemenu-icoToSub").toggleClass(activeClass);
            }
            if (e.type == "touchend") {
                touchMoveActive = false;
            }
        });
	}

});

function showMobileSubmenuLvl1(code,heading) {
    hideAllSubmenusLvl1();
    hideAllSubmenusLvl2();
    removeAllactiveLvl1();

    mobileMenuBackEl.classList.add("lvl1");
    document.getElementById("mobileSubmenu_" + code).classList.add(activeClass);
    document.getElementById("mobileMenuPoint_" + code).classList.add(activeClass);
    mobileMenuTitleEl.innerHTML = heading;
}

function showMobileSubmenuLvl2(code,heading) {
    mobileMenuBackEl.classList.remove("lvl1");
    mobileMenuBackEl.classList.add("lvl2");
    document.getElementById("mobileSubmenuLvl2_" + code).classList.add(activeClass);
    mobileMenuTitleEl.innerHTML = heading;
}

function hideAllSubmenusLvl1() {
    for (var i = 0; i < mobileSubmenus.length; i++) {
        mobileSubmenus[i].classList.remove(activeClass);
    }
}

function hideAllSubmenusLvl2() {
    for (var i = 0; i < mobileSubmenusLvl2.length; i++) {
        mobileSubmenusLvl2[i].classList.remove(activeClass);
    }
}

function removeAllactiveLvl1() {
    for (var i = 0; i < mobileMenuPointLvl1.length; i++) {
        mobileMenuPointLvl1[i].classList.remove(activeClass);
    }
}

function closeMobileMenu() {
    $("html, body").css({"overflow": "auto", "height": "auto"});
    $(window).scrollTop(scrollDistance); 
    mobileMenuContEl.classList.remove(activeClass);
    hideAllSubmenusLvl1();
    removeAllactiveLvl1();
    mobileMenuHomeEl.classList.add(activeClass);
    mobileMenuBodyEl.classList.remove(activeClass);
}

function setMobileMenuLvl1toTop(code) {
    let mobileSubmenuLvlHeight = mobileSubmenuCat.offsetHeight;

    document.getElementById("mobileSubmenuLvl2_" + code).style.height = mobileSubmenuLvlHeight+"px";

    for (var i = 0; i < mobileSubmenus.length; i++) {
        mobileSubmenus[i].scrollTop = 0;
    }
}