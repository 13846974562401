(function ( $ ) {
    'use strict';

    $.fn.extend({
        accountApiLogin: function (apiSettings) {
            var element = $(this);
            var apiSettings = apiSettings || {};
            var passwordField = element.find('input[type=\'password\']');
            var emailField = element.find('input[type=\'text\']');
            var csrfTokenField = element.find('input[type=\'hidden\']');
            var signInButton = element.find('.button');
            var validationField = passwordField.parent().parent().parent().find('.red.label');
            var checkValidationField = emailField.parent().parent().parent().find('.red.label');
            var checkTimeout = null;

            emailField.on("keydown", function(e) {
                if (e.keyCode == 13) {
                    e.preventDefault();

                    checkEmail(emailField.val(), emailField.data("url"));
                }
            });

            emailField.on("keyup", function(e) {
                clearTimeout(checkTimeout);

                if (e.keyCode == 13) {
                    return;
                }

                checkTimeout = setTimeout(function() {
                    checkEmail(emailField.val(), emailField.data("url"));
                }, 600);
            });

            passwordField.on("keydown", function(e) {
                if (e.keyCode == 13) {
                    e.preventDefault();

                    signInButton.click();
                }
            });

            signInButton.on("click", function(e) {
                e.preventDefault();
                emailField.parent().parent().parent().removeClass("error");
                emailField.parent().removeClass("error");
                passwordField.parent().parent().parent().removeClass("error");
                passwordField.parent().removeClass("error");
                $('.customerroremail').remove();
                $('.customerrorpass').remove();

                if (emailField.val() == '') {
                    emailField.parent().parent().parent().addClass("error");
                    emailField.parent().addClass("error");
                    $('.customerroremail').remove();
                    emailField.after( '<div class="alert alert-danger customerroremail">' + loginMessage + '</div>' );
                    return;
                }

                var formData = {
                    _username: emailField.val(),
                    _password: passwordField.val()
                };
                formData[csrfTokenField.attr('name')] = csrfTokenField.val();

                $.ajax({
                    method: apiSettings.method || "POST",
                    dataType: apiSettings.dataType || "json",
                    data: formData,
                    cache: false,
                    url: signInButton.data("url"),
                    success: function (response) {
                        passwordField.parent().parent().parent().removeClass("error");
                        passwordField.parent().removeClass("error");
                        $('.customerrorpass').remove();
                        location.reload();
                    },
                    error: function (jqXHR) {
                        passwordField.parent().parent().parent().addClass("error");
                        passwordField.parent().addClass("error");
                        $('.customerrorpass').remove();

                        if (jqXHR.status == 401) {
                            passwordField.after( '<div class="alert alert-danger customerrorpass">' + loginMessageWrong + '</div>' );
                        } else {
                            emailField.after( '<div class="alert alert-danger customerrorpass">' + jqXHR.responseJSON.message + '</div>' );
                        }
                    }
                });
            });

            function checkEmail(email, url) {
                emailField.parent().removeClass("error");
                $('.customerroremail').remove();

                checkValidationField.addClass('d-none');

                if (email == '') {
                    return;
                }

                $.ajax({
                    method: "GET",
                    dataType: "json",
                    data: { email: email },
                    cache: false,
                    url: url,
                    success: function (response) {
                        emailField.parent().parent().parent().removeClass("error");
                        emailField.parent().removeClass("error");
                        $('.customerroremail').remove();

                        passwordField.focus();
                    },
                    error: function (jqXHR) {
                        emailField.parent().parent().parent().addClass("error");
                        emailField.parent().addClass("error");
                        $('.customerroremail').remove();
                        if (jqXHR.status == 404) {
                            emailField.after( '<div class="alert alert-danger customerroremail">' + loginMessageNoRegistred + '</div>' );
                        } else {
                            emailField.after( '<div class="alert alert-danger customerroremail">' + jqXHR.responseJSON.message + '</div>' );
                        }
                    }
                });
            }
        }
    });
})( jQuery );