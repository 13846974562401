//po kliku na tlačítko otevřít blok s filtrama

$('.s31-mobile-filters').click(function () {
	setTimeout(function(){
		$('.s31-multifilters').addClass('opened');
		$('.s31-mobile-filters-show-more').addClass('opened');
		$('.s31-multifilters-filters').addClass('opened');
	}, 1);
	
	$('body').addClass('openedFilters');
	$('.s31-mobile-filters-back').fadeIn();
});

$('.s31-multifilters-filters .s31-multifilters-filters-close, .s31-mobile-filters-show-box button, .s31-mobile-filters-back').click(function () {
	$('.s31-multifilters').removeClass('opened');
	$('.s31-multifilters-filters').removeClass('opened');
	$('.s31-mobile-filters-show-more').removeClass('opened');
	
	$('body').removeClass('openedFilters');
	$('.s31-mobile-filters-back').fadeOut();
});
