function getHashParams() {
    var hashParams = {};
    var e,
        a = /\+/g,  // Regex for replacing addition symbol with a space
        r = /([^&;=]+)=?([^&;]*)/g,
        d = function (s) { return decodeURIComponent(s.replace(a, " ")); },
        q = window.location.hash.substring(1);

    while (e = r.exec(q)) {
        if (hashParams.hasOwnProperty(d(e[1]))) {
            if (!Array.isArray(hashParams[d(e[1])])) {
                hashParams[d(e[1])] = [hashParams[d(e[1])]];
            }

            hashParams[d(e[1])].push(d(e[2]));
        } else {
            hashParams[d(e[1])] = d(e[2]);
        }
    }

    return hashParams;
}

$.fn.isInViewport = function() {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
};