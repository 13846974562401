const orderTableContEl = document.querySelector('.s31-table-move');
const orderTableEl = document.querySelector('.s31-account-orders-table');

$(function () {
	$("#s31-dashboard-loadMore").click(function () {
		$(".s31-article-listingHidden").slideDown(300);
		$("#s31-dashboard-loadMore").fadeOut(300);
	});

	const favCount = document.getElementById("s31-account-countFav");
	const orderCount = document.getElementById("s31-account-countOrders");
	if (favCount) {
		if (currentNumberOfWishlistItems > 0) {
			favCount.style.display = "inline-block";
			favCount.innerHTML = currentNumberOfWishlistItems;
		}
	}
	if (orderCount) {
		if (currentNumberOfOrders > 0) {
			orderCount.style.display = "inline-block";
			orderCount.innerHTML = currentNumberOfOrders;
		}
	}

	$(".s31-table-move").on("scroll", function (e) {
		var elWidth = orderTableEl.offsetWidth;
		var elContScroll = orderTableContEl.scrollLeft;
		var elContWidth = orderTableContEl.offsetWidth;

		if(elContScroll + elContWidth >= elWidth) {
			$(".s31-table-move-gradient").fadeOut(200);
		} else {
			$(".s31-table-move-gradient").fadeIn(200);
		}
	});
});