$(function () {
	$(document).on("click", ".s31-checkbox-orderConfirmation", function () {
		if ($('#sylius_checkout_complete_agreement').is(':checked')) {
			$('#orderButtonActive').show();
			$('#orderButtonInactive').hide();
			$(".s31-checkbox-orderConfirmation").removeClass("s31-error");
			$(".s31-checkbox-orderConfirmation").removeClass("s31-error-animation");
		} else {
			$('#orderButtonActive').hide();
			$('#orderButtonInactive').show();
			$(".s31-checkbox-orderConfirmation").addClass("s31-error");
			$(".s31-checkbox-orderConfirmation").addClass("s31-error-animation");
		}
	});
	
	$(document).on("click", ".s31-checkbox-subscriptionConfirmation", function () {
		if ($('#sylius_checkout_complete_subscribedToNewsletter').is(':checked')) {
			dataLayer.push({ "event": "subscribe:newsletters" });
		}
	});

	$(document).on("click", "#orderButtonInactive", function () {
		$(".s31-checkbox-orderConfirmation").addClass("s31-error");
		$(".s31-checkbox-orderConfirmation").addClass("s31-error-animation");
		setTimeout(function () {
			$(".s31-checkbox-orderConfirmation").removeClass("s31-error-animation");
		}, 500);
	});
});