$(function () {
    if ($(".s31-vo-form .required").hasClass("error")) {
        $('html, body').animate({
			scrollTop: $(".s31-vo-form").offset().top
		}, 2000);
    }

    $(document).on("click", ".s31-landing-vo .s31-button-submit", function () {
		$('html, body').animate({
			scrollTop: $(".s31-vo-form").offset().top
		}, 2000);
	});

	$(document).on("click", ".s31-landing-vo .s31-contact-cat", function () {
		$('html, body').animate({
			scrollTop: $(".s31-vo-form").offset().top
		}, 2000);
	});
});