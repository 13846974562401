$(function () {
	//Matchmedia korespondující s mediaquaries pro bootstrap

	if (mqxl.matches) {
		//XL nad 1199px


	} else if (mqlg.matches) {
		//LG nad 991px

	

	} else if (mqmd.matches) {
		//MD nad 767px

	$('.s31-linkTitle').click(function (e) {
		var parentHandle = $(this).parent('.s31-footer-blocks');
		parentHandle.find(".s31-links").slideToggle();
		parentHandle.find(".s31-sub-menu-icon").toggleClass("opened");
	});
			
			
	} else if (mqsm.matches) {
		//SM nad 575px

	$('.s31-linkTitle').click(function (e) {
		var parentHandle = $(this).parent('.s31-footer-blocks');
		parentHandle.find(".s31-links").slideToggle();
		parentHandle.find(".s31-sub-menu-icon").toggleClass("opened");
	});

	} else {
		//XS do 575px


	$('.s31-linkTitle').click(function (e) {
		var parentHandle = $(this).parent('.s31-footer-blocks');
		parentHandle.find(".s31-links").slideToggle();
		parentHandle.find(".s31-sub-menu-icon").toggleClass("opened");
	});

	}

	// Ostatní JS společné pro všechny zařízení
	//----------------------------------------------------------------------
});