
const icoReaderButtEl = document.getElementById("s31-icoReaderButt");
const icoReaderEl = document.getElementById("s31-icoReader");
const icoErrorBlockEl = document.getElementById("s31-icoErrorBlock");
const icoReaderManualButtEl = document.getElementById("s31-icoReaderButtManual");
const companyBlockEl = document.getElementById("s31-checkout-company-block");
const icoReaderBlockEl = document.getElementById("s31-checkout-icoReader-block");
const addressFormEl = document.getElementById("s31-checkout-address-pane");

const icoInputEl = document.getElementById("sylius_checkout_address_billingAddress_crn");
const companyInputEl = document.getElementById("sylius_checkout_address_billingAddress_company");
const dicInputEl = document.getElementById("sylius_checkout_address_billingAddress_vatId");
const streetInputEl = document.getElementById("sylius_checkout_address_billingAddress_street");
const cpInputEl = document.getElementById("sylius_checkout_address_billingAddress_streetNumber");
const cityInputEl = document.getElementById("sylius_checkout_address_billingAddress_city");
const postcodeInputEl = document.getElementById("sylius_checkout_address_billingAddress_postcode");

//registracni form
const icoInputElReg = document.getElementById("sylius_customer_registration_defaultAddress_crn");
const companyInputElReg = document.getElementById("sylius_customer_registration_defaultAddress_company");
const dicInputElReg = document.getElementById("sylius_customer_registration_defaultAddress_vatId");
const streetInputElReg = document.getElementById("sylius_customer_registration_defaultAddress_street");
const cpInputElReg = document.getElementById("sylius_customer_registration_defaultAddress_streetNumber");
const cityInputElReg = document.getElementById("sylius_customer_registration_defaultAddress_city");
const postcodeInputElReg = document.getElementById("sylius_customer_registration_defaultAddress_postcode");

var checkout_sylius_register_form = document.getElementById('s31-form-register');
var isRegisterForm = false;
if(typeof(checkout_sylius_register_form) != 'undefined' && checkout_sylius_register_form != null){
    var isRegisterForm = true;
}

$(function () {
    $("#s31-checkout-api-login").apiCheckoutLogin();

    $("#s31-checkout-api-login").addClass("d-none");
    $(".s31-login-address-h2").addClass("d-none");
    $("#s31-checkout-address-pane").removeClass("d-none");
    $(".s31-checkout-summary-address-cont").removeClass("d-none");
    $(".s31-checkout-address-h2").removeClass("d-none");

    if (addressFormEl) {
        if (addressFormEl.getElementsByClassName("error").length > 0) {
            $("#s31-checkout-api-login").addClass("d-none");
            $(".s31-login-address-h2").addClass("d-none");
            $("#s31-checkout-address-pane").removeClass("d-none");
            $(".s31-checkout-summary-address-cont").removeClass("d-none");
            $(".s31-checkout-address-h2").removeClass("d-none");
    
            // if ($("#sylius_checkout_address_differentShippingAddress").is(":checked")) {
            //     $(".s31-checkout-address-note").removeClass("d-none");
            // }
    
            if ($("#sylius_checkout_address_differentShippingAddress").is(":checked")) {
                $("#s31-shipping-address").removeClass("d-none");
            }
    
            if ($("#sylius_checkout_address_optionalRegistration").is(":checked")) {
                $("#s31-optional-registration").removeClass("d-none");
                $(".s31-checkout-astoreo-box").addClass("active");
            }
    
            if ($("#sylius_checkout_address_addressTypeCompany").is(":checked")) {
                $("#s31-checkout-icoReader-block").removeClass("d-none");
                $("#s31-checkout-company-block").removeClass("d-none");
    
                $(".s31-checkout-company-data").each(function() {
                    $( this ).removeClass("d-none");
                });
            }
    
        }
    }

    $("#s31-checkout-continue-as-guest").on("click", function (e) {
        e.preventDefault();

        $("#s31-checkout-api-login").addClass("d-none");
        $(".s31-login-address-h2").addClass("d-none");
        $("#s31-checkout-address-pane").removeClass("d-none");
        $(".s31-checkout-summary-address-cont").removeClass("d-none");
        $(".s31-checkout-address-h2").removeClass("d-none");
        
        $("#sylius_checkout_address_customer_email").val($("#sylius_checkout_address_login_username").val());
    });

    if (icoReaderButtEl) {
        icoReaderButtEl.addEventListener("click",  function(){
            icoReaderButtEl.classList.add("s31-loadingIco");
            var ico = icoReaderEl.value;
            if (ico > "") {
                fetchIcoData(ico);
            } else {
                showIcoError('empty');
                icoReaderButtEl.classList.remove("s31-loadingIco");
            }
        });
    }

    if (icoReaderManualButtEl) {
        icoReaderManualButtEl.addEventListener("click",  function(){
            companyBlockEl.classList.remove("d-none");
        });
    }

   
    
    var checkbox = document.getElementById('s31-adress-note');
    if (checkbox) {
        checkbox.addEventListener("change", openaddressnote, false);
    }

    function openaddressnote(){
        var isChecked = checkbox.checked;
        
        if(isChecked) {
            document.querySelector('.s31-checkout-address-note').classList.remove('d-none');
        } else { 
            document.querySelector('.s31-checkout-address-note').classList.add('d-none');
        }
    }
	
    var checkboxAddressType = document.getElementById('sylius_checkout_address_addressTypeCompany');
    if (checkboxAddressType) {
        checkboxAddressType.addEventListener("change", showcompanyaddress, false);
        showcompanyaddress();
    }
	
	function showcompanyaddress(){
		var isChecked = checkboxAddressType.checked;
		var divs_company_data = document.querySelectorAll('.s31-checkout-company-data');

		if(isChecked) {
			for (var i = 0; i < divs_company_data.length; i++) {
				divs_company_data[i].classList.remove('d-none');
			}
			document.getElementById('sylius_checkout_address_addressType').value = 1;
        } else { 
            for (var i = 0; i < divs_company_data.length; i++) {
				divs_company_data[i].classList.add('d-none');
			}
			document.getElementById('sylius_checkout_address_addressType').value = 0;
        }
    }

    // Registrace v checkoutu heslo 1
    var inputPassCheckout = document.getElementById('sylius_checkout_address_user_plainPassword_first'),
    iconCheckout = document.getElementById('s31-icon-checkout');

    if (iconCheckout) {
        iconCheckout.onclick = function () {
            if (inputPassCheckout.className == 'active') {
                inputPassCheckout.setAttribute('type', 'password');
                iconCheckout.className = 's31-eye-icon';
                inputPassCheckout.className = '';
            } else {
                inputPassCheckout.setAttribute('type', 'text');
                iconCheckout.className = 's31-eye-icon active';
                inputPassCheckout.className = 'active';
            }
        }
    }

    // Registrace v checkoutu heslo 2
    var inputPassCheckout2 = document.getElementById('sylius_checkout_address_user_plainPassword_second'),
    iconCheckout2 = document.getElementById('s31-icon-checkout2');

    if (iconCheckout2) {
        iconCheckout2.onclick = function () {
            if (inputPassCheckout2.className == 'active') {
                inputPassCheckout2.setAttribute('type', 'password');
                iconCheckout2.className = 's31-eye-icon';
                inputPassCheckout2.className = '';
            } else {
                inputPassCheckout2.setAttribute('type', 'text');
                iconCheckout2.className = 's31-eye-icon active';
                inputPassCheckout2.className = 'active';
            }
        }
    }

    // Registrace heslo 1
    var inputPass = document.getElementById('sylius_customer_registration_user_plainPassword_first'),
    icon = document.getElementById('s31-icon');

    if (icon) {
        icon.onclick = function () {
            if (inputPass.className == 'active') {
                inputPass.setAttribute('type', 'password');
                icon.className = 's31-eye-icon';
                inputPass.className = '';
            } else {
                inputPass.setAttribute('type', 'text');
                icon.className = 's31-eye-icon active';
                inputPass.className = 'active';
            }
        }
    }

    // Registrace heslo 2
    var inputPass2 = document.getElementById('sylius_customer_registration_user_plainPassword_second'),
    icon2 = document.getElementById('s31-icon2');

    if (icon2) {
        icon2.onclick = function () {
            if (inputPass2.className == 'active') {
                inputPass2.setAttribute('type', 'password');
                icon2.className = 's31-eye-icon';
                inputPass2.className = '';
            } else {
                inputPass2.setAttribute('type', 'text');
                icon2.className = 's31-eye-icon active';
                inputPass2.className = 'active';
            }
        }
    }

    // Zapomenuté heslo soucasne
    var inputPassCurrent = document.getElementById('sylius_user_change_password_currentPassword'),
    iconCurrent = document.getElementById('s31-icon-current');

    if (iconCurrent) {
        iconCurrent.onclick = function () {
            if (inputPassCurrent.className == 'active') {
                inputPassCurrent.setAttribute('type', 'password');
                iconCurrent.className = 's31-eye-icon';
                inputPassCurrent.className = '';
            } else {
                inputPassCurrent.setAttribute('type', 'text');
                iconCurrent.className = 's31-eye-icon active';
                inputPassCurrent.className = 'active';
            }
        }
    }

    // Zapomenuté heslo prvni
    var inputPassNew = document.getElementById('sylius_user_change_password_newPassword_first'),
    iconNewPass = document.getElementById('s31-icon-new');

    if (iconNewPass) {
        iconNewPass.onclick = function () {
            if (inputPassNew.className == 'active') {
                inputPassNew.setAttribute('type', 'password');
                iconNewPass.className = 's31-eye-icon';
                inputPassNew.className = '';
            } else {
                inputPassNew.setAttribute('type', 'text');
                iconNewPass.className = 's31-eye-icon active';
                inputPassNew.className = 'active';
            }
        }
    }

    // Zapomenuté heslo druhe
    var inputPassSecond = document.getElementById('sylius_user_change_password_newPassword_second'),
    iconCopyPass = document.getElementById('s31-icon-copy');

    if (iconCopyPass) {
        iconCopyPass.onclick = function () {
            if (inputPassSecond.className == 'active') {
                inputPassSecond.setAttribute('type', 'password');
                iconCopyPass.className = 's31-eye-icon';
                inputPassSecond.className = '';
            } else {
                inputPassSecond.setAttribute('type', 'text');
                iconCopyPass.className = 's31-eye-icon active';
                inputPassSecond.className = 'active';
            }
        }
    }

    // Změnit heslo prvni
    var inputChangePassFirst = document.getElementById('sylius_user_reset_password_password_first'),
    iconChangePassFirst = document.getElementById('s31-icon-first');

    if (iconChangePassFirst) {
        iconChangePassFirst.onclick = function () {
            if (inputChangePassFirst.className == 'active') {
                inputChangePassFirst.setAttribute('type', 'password');
                iconChangePassFirst.className = 's31-eye-icon';
                inputChangePassFirst.className = '';
            } else {
                inputChangePassFirst.setAttribute('type', 'text');
                iconChangePassFirst.className = 's31-eye-icon active';
                inputChangePassFirst.className = 'active';
            }
        }
    }

    // Změnit heslo druhe
    var inputChangePassSecond = document.getElementById('sylius_user_reset_password_password_second'),
    iconChangePassSecond = document.getElementById('s31-icon-second');

    if (iconChangePassSecond) {
        iconChangePassSecond.onclick = function () {
            if (inputChangePassSecond.className == 'active') {
                inputChangePassSecond.setAttribute('type', 'password');
                iconChangePassSecond.className = 's31-eye-icon';
                inputChangePassSecond.className = '';
            } else {
                inputChangePassSecond.setAttribute('type', 'text');
                iconChangePassSecond.className = 's31-eye-icon active';
                inputChangePassSecond.className = 'active';
            }
        }
    }

    $('#sylius_checkout_address_optionalRegistration').change(function () {
        $('#s31-optional-registration').toggleClass('d-none');
        $(".s31-checkout-astoreo-box").toggleClass("active");
    });

    $('#sylius_checkout_address_differentShippingAddress').change(function () {
        $('#s31-shipping-address').toggleClass('d-none');
    });

    $("#s31-billing-address").addressBook();
    $("#s31-shipping-address").addressBook();

    $("form[name='sylius_checkout_address']").on("keyup keypress", function(e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            e.preventDefault();
            return false;
        }
    });
});


function fetchIcoData(query){
    $.get( "/ajax/shipping/ares/" + query, function( data ) {
        fillRegistrationForm(data);
    });
}

function fillRegistrationForm(data) {
    if (data.error == true) {
        showIcoError("wrong");
    } else {
        isCompanyUsed = true;

        companyBlockEl.classList.remove("d-none");
        icoReaderBlockEl.classList.add("d-none");
        icoReaderEl.parentNode.classList.remove("error");

        if(isRegisterForm){
            icoInputElReg.value = data.ico;
            companyInputElReg.value = data.company;
            dicInputElReg.value = data.dic;
            streetInputElReg.value = data.street;
            cpInputElReg.value = data.house_number;
            cityInputElReg.value = data.city;
            postcodeInputElReg.value = data.zipcode;
        }
        else {
            icoInputEl.value = data.ico;
            companyInputEl.value = data.company;
            dicInputEl.value = data.dic;
            streetInputEl.value = data.street;
            cpInputEl.value = data.house_number;
            cityInputEl.value = data.city;
            postcodeInputEl.value = data.zipcode;
        }
    }

    icoReaderButtEl.classList.remove("s31-loadingIco");

}

function showIcoError(error) {
    if (error == "empty") {
        icoReaderEl.parentNode.classList.add("error");
        icoErrorBlockEl.classList.remove("d-none");
        icoErrorBlockEl.innerHTML = icoEmptyMessage;
    } else if (error == "wrong") {
        icoReaderEl.parentNode.classList.add("error");
        companyBlockEl.classList.remove("d-none");
        icoErrorBlockEl.classList.remove("d-none");
        icoErrorBlockEl.innerHTML = icoErrorMessage;
    }
    
}

// registrace - adresa
$(function() {
    var checkbox_address = document.getElementById('sylius_customer_registration_enterAddress');
    if (checkbox_address) {
        checkbox_address.addEventListener("change", showaddressform, false);
        showaddressform();
    }

    function showaddressform() {
        var isChecked = checkbox_address.checked;
        var divs_address_data = document.querySelectorAll('.s31-billing-address-box');

        if (isChecked) {
            for (var i = 0; i < divs_address_data.length; i++) {
                divs_address_data[i].classList.remove('d-none');
            }
        } else {
            for (var i = 0; i < divs_address_data.length; i++) {
                divs_address_data[i].classList.add('d-none');
            }
        }
    }

    //registrace - firemní adresa
    var checkbox_company_address = document.getElementById('sylius_customer_registration_addressTypeCompany');
    if (checkbox_company_address) {
        checkbox_company_address.addEventListener("change", showcompanyaddressformregister, false);
        showcompanyaddressformregister();
    }

    function showcompanyaddressformregister() {
        var isCompanyChecked = checkbox_company_address.checked;
        var divs_address_data = document.querySelectorAll('.s31-company-address-box');

        if (isCompanyChecked) {
            for (var i = 0; i < divs_address_data.length; i++) {
                divs_address_data[i].classList.remove('d-none');
            }
            $("#s31-checkout-icoReader-block").removeClass("d-none");

            $("#sylius_customer_registration_enterAddress").attr("checked", "checked");
            showaddressform();
        } else {
            for (var i = 0; i < divs_address_data.length; i++) {
                divs_address_data[i].classList.add('d-none');
            }
            $("#s31-checkout-icoReader-block").addClass("d-none");

            icoReaderEl.parentNode.classList.remove("error");
            icoErrorBlockEl.classList.add("d-none");
            icoErrorBlockEl.innerHTML = "";
        }

    }
});

// save data
if($('[name="sylius_checkout_address"]').length !== 0) {

    $(document).on('change', '[name="sylius_checkout_address"]', function() {
        var data = $('[name="sylius_checkout_address"]').serialize();

        $.ajax({
            method: 'POST',
            data: data,
            cache: false,
            url: "/ajax/save-personal-data",
            success: function (response) {
                //var $payload = $(response);
            },
            error: function (jqXHR) {

            },
            complete: function() {

            }
        });
    });

}

var checkTimeoutPurchaseType = null;
if($("#s31-change-purchase-type").length !== 0){
    $("#s31-change-purchase-type").hide();

    var emailInput = document.getElementById("sylius_checkout_address_customer_email");

    emailInput.addEventListener("keyup", checkEmailExist, false);
    window.addEventListener("load", checkEmailExist, false);
}

function checkEmailExist(){
    var emailInputVal = emailInput.value;
    var emailInputUrl = emailInput.getAttribute("data-url");

    if (emailInputVal == '' || emailInputUrl == 'emailInputUrl') {
        return;
    }

    clearTimeout(checkTimeoutPurchaseType);
    checkTimeoutPurchaseType = setTimeout(function() {
        checkIfEmailExists(emailInputVal, emailInputUrl);
    }, 400);
}

function checkIfEmailExists(email, url){
    $.ajax({
        method: "GET",
        dataType: "json",
        data: { email: email },
        cache: false,
        url: url,
        success: function (response) {
            $("#s31-change-purchase-type").show();
        },
        error: function (jqXHR) {
            $("#s31-change-purchase-type").hide();
        }
    });

    //check flatrate
    $.ajax({
        method: "GET",
        dataType: "json",
        data: { email: email },
        cache: false,
        url: "/ajax/customers/flat-rate",
        success: function (response) {
            $.ajax({
                method: "GET",
                dataType: "html",
                cache: false,
                url: "/ajax/checkout/address-summary",
                success: function (response) {
                    var $payload = $(response);

                    if ($payload.find(".s31-checkout-summary-box").length > 0) {
                        $(".s31-checkout-summary-box").html($payload.find(".s31-checkout-summary-box").html());
                    }
                }
            });
        }
    });
}

if($("#sylius_checkout_address_isLoadDataFromSession").length !== 0){
    if($("#sylius_checkout_address_isLoadDataFromSession").val() == 1){
        $("#s31-checkout-api-login").addClass("d-none");
        $(".s31-login-address-h2").addClass("d-none");
        $("#s31-checkout-address-pane").removeClass("d-none");
        $(".s31-checkout-summary-address-cont").removeClass("d-none");
        $(".s31-checkout-address-h2").removeClass("d-none");

        if ($("#sylius_checkout_address_differentShippingAddress").is(":checked")) {
            $("#s31-shipping-address").removeClass("d-none");
        }

        if ($("#sylius_checkout_address_addressTypeCompany").is(":checked")) {
            $("#s31-checkout-icoReader-block").removeClass("d-none");
            $("#s31-checkout-company-block").removeClass("d-none");

            $(".s31-checkout-company-data").each(function() {
                $( this ).removeClass("d-none");
            });
        }

        if($("#sylius_checkout_address_notes").val() != ""){
            document.getElementById("s31-adress-note").checked = true;
            document.querySelector('.s31-checkout-address-note').classList.remove('d-none');
        }
    }
}

if($("#s31-change-purchase-type").length !== 0 ){
    $("#s31-change-purchase-type a").on("click", function (e) {
		var sylius_checkout_address_customer_email = $("#sylius_checkout_address_customer_email").val();
		$("#sylius_checkout_address_login_username").val(sylius_checkout_address_customer_email);
        $(".s31-password-row").removeClass("d-none");
        $(".s31-address-buttons a").removeClass("d-none");
        $("#s31-checkout-continue-as-guest").text("Pokračovat bez přihlášení");
		
        $("#s31-checkout-api-login").removeClass("d-none");
        $(".s31-login-address-h2").removeClass("d-none");
        $("#s31-checkout-address-pane").addClass("d-none");
        $(".s31-checkout-summary-address-cont").addClass("d-none");
        $(".s31-checkout-address-h2").addClass("d-none");
    });
}
