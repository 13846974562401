(function ($) {
	'use strict';

	var beforeSubmitTimeout = null;

	$.fn.extend({
		quantitySelector: function (selector) {
			var elements = $(this);

			if (typeof selector === "undefined") {
				elements.each(function (index, element) {
					var element = $(element);

					element.find(".s31-pocetks-plus, .s31-pocetks-minus").on("click", function (e) {
						var value = parseInt(element.find("input[type='text']").val()),
								newValue = 0;

						

						if ($(this).hasClass('s31-disabled')) {
							return;
						}

						e.preventDefault();

						if ($(this).hasClass("s31-pocetks-minus")) {
							newValue = value - 1;
						} else {
							newValue = value + 1;
						}

						setValue(element, newValue);
					});

					element.find("input").on("change", function (e) {
						var value = this.value;

						if (!value.match(/[0-9]+/)) {
							value = 0
						}

						setValue(element, value);
					});
				});
			} else {
				$(document).on("click", selector + " .s31-pocetks-plus, " + selector + " .s31-pocetks-minus", function (e) {
					var element = $(this).parents(selector),
							value = parseInt(element.find("input[type='text']").val()),
							newValue = 0;

					$(this).parent().parent().parent().addClass("s31-loader-spinning");

                    if ($(this).hasClass('s31-disabled')) {
                        return;
                    }

					e.preventDefault();

					if ($(this).hasClass("s31-pocetks-minus")) {
						newValue = value - 1;
					} else {
						newValue = value + 1;
					}

					setValue(element, newValue);
				});

				$(document).on("change", selector + " input", function (e) {
					var element = $(this).parents(selector),
							value = this.value;

					var match = value.match(/[0-9]+/);
                    value = 0;
					if (match) {
						value = match[0];
					}

					setValue(element, value);
				});
			}
		}
	});

	var setValue = function (element, value) {
		var input = element.find("input[type='text']"),
			newValue = parseInt(value),
			minValue = parseInt(input.prop("min")),
			maxValue = parseInt(input.prop("max")),
			tracked = input.data("tracked"),
			form = $("form[name='sylius_cart']");

		if (!value || value < 0) {
			newValue = minValue;
		}

		if ((value < minValue) && tracked) {
			newValue = minValue;
		}

		if ((value > maxValue) && tracked) {
			newValue = maxValue;
        }

        if (tracked === false) {
        	if (newValue === minValue) {
				element.find(".s31-pocetks-minus").addClass("s31-disabled");
				element.find(".s31-pocetks-plus").removeClass("s31-disabled");
			} else {
				element.find(".s31-pocetks-minus").removeClass("s31-disabled");
				element.find(".s31-pocetks-plus").removeClass("s31-disabled");
			}
		} else if (newValue === minValue === maxValue) {
			element.find(".s31-pocetks-minus").addClass("s31-disabled");
			element.find(".s31-pocetks-plus").addClass("s31-disabled");
		} else {
			if (newValue === minValue) {
				element.find(".s31-pocetks-minus").addClass("s31-disabled");
			} else {
				element.find(".s31-pocetks-minus").removeClass("s31-disabled");
			}
			if (newValue === maxValue) {
				element.find(".s31-pocetks-plus").addClass("s31-disabled");
			} else {
				element.find(".s31-pocetks-plus").removeClass("s31-disabled");
			}
		}

		input.val(newValue);
		input.trigger("input");

		if (form.length == 1) {
			clearTimeout(beforeSubmitTimeout);
			beforeSubmitTimeout = setTimeout(function () {
				form.submit();
			}, 500);
		}
	}

})(jQuery);
