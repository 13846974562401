/**
 * Definition of events for cart
 */

const s31shop_cart_event = {
    'added': 's31shop_cart_event_added',
    'removed': 's31shop_cart_event_removed'
};

// Add to cart
var s31shop_cart_event_added = {};
//  Remove from cart
var s31shop_cart_event_removed = {};