(function ($) {
	'use strict';

	$.fn.extend({
		addToCart: function (selector) {
			var elements = $(this);

			if (typeof selector === "undefined") {
				elements.each(function (index, element) {
					var element = $(element);

					element.on("submit", function (e) {
						if ($(this).data('embvalidate') == '1') {
							e.preventDefault();
							e.stopPropagation();
							if (isEmbroideryTextValid()) {
								submitFunction(this);
							}
						} else {
							if ($(this).data('classic') == '1') {
								return true;
							} else {
								e.preventDefault();
								e.stopPropagation();
	
								submitFunction(this);
							}
							return false;
						}
					});
				});
			} else {
				$(document).on("submit", selector, function (e) {
					if ($(this).data('embvalidate') == '1') {
						e.preventDefault();
						e.stopPropagation();
						if (isEmbroideryTextValid()) {
							submitFunction(this);
						}
					} else {
						if ($(this).data('classic') == '1') {
							return true;
						} else {
							e.preventDefault();
							e.stopPropagation();
	
							submitFunction(this);
						}
	
						return false;
					}
				});
			}
		}
	});

	$("#addToCartModal .btn-primary").on("click", function (e) {
		window.location.href = $(this).data("redirectUrl");
	});

	var activeRequest = false;

	

	var submitFunction = function (element) {
		var $this = $(element);

		var href = $this.attr('action');
		var redirectUrl = $this.data('redirect');
		var productId = $this.data('productid');
		var productBox = $('#s31-article-box-' + productId);
		var validationElement = $('#sylius-cart-validation-error-' + productId);
		var validationPointsElement = $('#sylius-cart-validation-points-error-' + productId);

		if (activeRequest) {
			return;
		}

        activeRequest = true;
		$(".s31-loader-" + productId + " button svg.s31-loadingIco").show();
		$(".s31-loader-" + productId + " button svg.s31-cartWhite").hide();
		$(".s31-loader-" + productId + " button span").text(loading);

		var method = "POST";
		if ($this.find("input[name='_method']").length == 1) {
			method = $this.find("input[name='_method']").val();
		}

		$.ajax({
			method: method,
			data: $this.serialize(),
			cache: false,
			url: href,
			success: function (response) {
				validationElement.addClass('d-none');

				$("#add-to-cart-modal-validation-error").addClass("d-none");

				if (response.hasOwnProperty("product")) {
					//reset original price
					$("#addToCartModal .s31-addToCart-productPrice-original").text("");

					$("#addToCartModal .s31-addToCart-productTitle").text(response.product.name);
					$("#addToCartModal .s31-addToCart-productAmount").text(pieces + response.product.quantity);
					$("#addToCartModal .s31-addToCart-productPrice").text(price_qty + ": " + response.product.unitPrice);
					if (response.product.originalPrice != 0 && parseInt(response.product.originalPrice.replace(/\D/g, "")) > parseInt(response.product.unitPrice.replace(/\D/g, ""))) {
						$("#addToCartModal .s31-addToCart-productPrice-original").text(response.product.originalPrice);
					}
					$("#addToCartModal .s31-addToCart-productBrand").text(response.product.brand);
					$("#addToCartModal .s31-addToCart-mainImage").prop("src", response.product.image);
					$("#addToCartModal .s31-addToCart-productPrice-total").text(price_all + ": " + response.product.totalQuantityPrice);

					$("#addToCartModal .s31-addToCart-productColor").text("");
					$("#addToCartModal .s31-addToCart-productSize").text("");

					if(response.product.color != "")
						$("#addToCartModal .s31-addToCart-productColor").text(color + ": " + response.product.color);
					if(response.product.size != "")
						$("#addToCartModal .s31-addToCart-productSize").text(size + ": " + response.product.size);

					if (response.product.hasOwnProperty("stockSufficientMessage") && response.product.stockSufficientMessage != "") {
						$("#add-to-cart-modal-validation-error").text(response.product.stockSufficientMessage).removeClass("d-none");
					}
				}

				$("#addToCartModal .btn-primary").data("redirectUrl", redirectUrl);
				if (!response.hasOwnProperty("redirectUrl") || !$("#add-to-cart-modal-validation-error").hasClass("d-none")) {
					$("#addToCartModal").modal("show");
					if ($(".swiper-popup").length) {
						swiperPopup.update();
					}
				}

				$("#s31-cart-layer, #s31-cart-layer-mobile").html(response.cartLayer);
				$("#cart-button .s31-ico").addClass("active");
				$("#cart-button .s31-ico-text").addClass("active");
				$("#addToCartModal .s31-addToCart-freeDelivery").replaceWith(response.freeDeliveryLayer);

				if (response.hasOwnProperty("s31layer") && typeof dataLayer !== 'undefined') {
					dataLayer.push(response.s31layer);
				}

				if (response.hasOwnProperty("gtmProduct")) {
					s31shop_cart_event_added = new CustomEvent("s31shop_cart_event_added", {
						"detail": {
							"event": "addToCart",
							"ecommerce": {
								"currencyCode": window.gtmEnhancedEcommerceCurrencyCode || "",
								"add": {
									"products": [response.gtmProduct]
								}
							}
						}
					});
					document.dispatchEvent(s31shop_cart_event_added);
				}

				if (response.hasOwnProperty("redirectUrl") && $("#add-to-cart-modal-validation-error").hasClass("d-none")) {
					window.location.href = response.redirectUrl;
				}

				// If product was added on cart page, page wil reload
				if (window.location.pathname === '/cart/') {
					window.location.href = redirectUrl;
				}
			},
			error: function (jqXHR) {
				validationElement.removeClass('d-none');

				productBox.addClass('s31-error');
				var validationMessage = '';

				if (jqXHR.responseJSON !== "") {
					var response = jqXHR.responseJSON;

					$.each(response.errors.errors, function (key, message) {
						validationMessage += message;
					});

					validationElement.html(validationMessage);

				}
				$this.removeClass('loading');
			},
            complete: function() {
				$(".s31-addToCart button svg.s31-loadingIco").hide();
				$(".s31-addToCart button svg.s31-cartWhite").show();
				$(".s31-addToCart button span").text(addToCart);
                activeRequest = false;
			}
		});
	};

	$("form[name='add_products_to_cart']").on("submit", function (e) {
		e.preventDefault();

		var $this = $(this);
		var href = $this.attr('action');
		$("#addToCartModal").removeClass("s31-popupSuccess");
		$("#addToCartModal").removeClass("s31-popupError");

		$.ajax({
			method: 'POST',
			data: $this.serialize(),
			cache: false,
			url: href,
			success: function (response) {
				$("#add-to-cart-modal-validation-error").addClass("d-none");
				$("#addToCartModal .s31-addToCart-popupBody").removeClass("d-block");
				$("#addToCartModal").modal("show");
				if ($(".swiper-popup").length) {
					swiperPopup.update();
				}
				$("#addToCartModal").addClass("s31-popupSuccess");

				if (response.errors.errors.length != 0) {
					var validationMessage = '';

					$.each(response.errors.errors, function (key, message) {
						validationMessage += message;
					});

					$("#addToCartModal").modal("show");
					if ($(".swiper-popup").length) {
						swiperPopup.update();
					}
					$("#addToCartModal .s31-addToCart-popupBody").addClass("d-block");
					$("#add-to-cart-modal-validation-error").text(validationMessage).removeClass("d-none");
				}

				$("#s31-cart-layer, #s31-cart-layer-mobile").html(response.cartLayer);
			},
			error: function (jqXHR) {
				var validationMessage = '';

				$("#add-to-cart-modal-validation-error").addClass("d-none");
				$("#addToCartModal .s31-addToCart-popupBody").removeClass("d-block");

				if (jqXHR.responseJSON !== "") {
					var response = jqXHR.responseJSON;

					$.each(response.errors.errors, function (key, message) {
						validationMessage += message;
					});

					$("#addToCartModal").addClass("s31-popupError");
					$("#addToCartModal").modal("show");
					if ($(".swiper-popup").length) {
						swiperPopup.update();
					}
					$("#add-to-cart-modal-validation-error").text(validationMessage).removeClass("d-none");
				}
			}
		});

		return false;
	});

	$(document).on("change", ".s31-shop-service-product input", function (e) {
		var $this = $(this);

		if (this.checked) {
			$.ajax({
				method: 'POST',
				cache: false,
				url: $(this).data("add"),
				success: function (response) {
					var $payload = $(response);

					processCartSummaryResponse($payload);
				},
				error: function (jqXHR) {
				}
			});

			$this.parents(".s31-cart-services-box").find(".s31-cart-removeButt").removeClass("s31-hidden");
		} else {
			$.ajax({
				method: 'POST',
				cache: false,
				url: $(this).data("remove"),
				success: function (response) {
					var $payload = $(response);

					processCartSummaryResponse($payload);
				},
				error: function (jqXHR) {
				}
			});

			$this.parents(".s31-cart-services-box").find(".s31-cart-removeButt").addClass("s31-hidden");
		}
	});

	$(document).on("click", ".s31-cart-services-box .s31-cart-removeButt", function(e) {
		e.preventDefault();

		var $this = $(this);

		$this.addClass("s31-hidden");
		$this.parents(".s31-cart-services-box").find("input[type='checkbox']").prop("checked", false);

		$.ajax({
			method: 'POST',
			cache: false,
			url: $(this).data("remove"),
			success: function (response) {
				var $payload = $(response);

				processCartSummaryResponse($payload);
			},
			error: function (jqXHR) {
			}
		});

		$this.parents(".s31-cart-services-box").find(".s31-cart-removeButt").addClass("s31-hidden");
	});

	$(document).on("click", ".s31-cart-action-box .s31-cart-removeButt", function(e) {
		e.preventDefault();

		var $this = $(this);

		$.ajax({
			method: 'POST',
			cache: false,
			url: $(this).data("url"),
			success: function (response) {
				var $payload = $(response);

				processCartSummaryResponse($payload);
			},
			error: function (jqXHR) {
			}
		});

		$this.parents(".s31-cart-action-box").find(".s31-cart-removeButt").addClass("s31-hidden");
	});

	$(document).on("change", ".s31-cart-gift-choose input", function (e) {
		var $this = $(this);

		if (this.checked) {
			if (this.value == "remove") {
				if ($(this).data("remove")) {
					$.ajax({
						method: 'POST',
						cache: false,
						url: $(this).data("remove"),
						success: function (response) {
							var $payload = $(response);

							processCartSummaryResponse($payload);
						},
						error: function (jqXHR) {
						}
					});
				}
			} else {
				var addUrl = false;
				if ($(this).data("add")) {
					addUrl = $(this).data("add");
				} else {
					if ($(this).parents(".s31-cart-gift-product").find("select.s31-cart-gift-select-color option").length == 1) {
						var selectedSize = $(this).parents(".s31-cart-gift-product").find("select.s31-cart-gift-select-size option:selected");
						if (selectedSize.length) {
							addUrl = selectedSize.eq(0).data("add");
						}
					}
				}

				if (addUrl != false) {
					$.ajax({
						method: 'POST',
						cache: false,
						url: addUrl,
						success: function (response) {
							var $payload = $(response);

							processCartSummaryResponse($payload);
						},
						error: function (jqXHR) {
						}
					});
				}
			}
		}
	});

	$(document).on("change", ".s31-cart-gift-select-color", function(e) {
		var data = $(this).data("variants"),
			addUrlTemplate = $(this).data("add-template"),
			selectedColor = this.value,
			sizeSelect = $(this).parents(".s31-cart-gift-select-box").find(".s31-cart-gift-select-size");

		var options = "";
		for (var i in data[selectedColor]) {
			var size = data[selectedColor][i],
				addUrl = addUrlTemplate.replace("%23%23productId%23%23", size.id),
				selected = data[selectedColor].length == 1 ? " selected" : "";

			options += "<option value='" + size.id + "' data-add='" + addUrl + "'" + selected+ ">" + size.key + "</option>";
		}

		sizeSelect.html(options);
		if (data[selectedColor].length == 1) {
			sizeSelect.parent().hide();
			sizeSelect.change();
		} else {
			sizeSelect.parent().show();
		}
	});

	$(document).on("change", ".s31-cart-gift-select-size", function(e) {
		$.ajax({
			method: 'POST',
			cache: false,
			url: $(this).children("option:selected").data("add"),
			success: function (response) {
				var $payload = $(response);

				processCartSummaryResponse($payload);
			},
			error: function (jqXHR) {
			}
		});
	});

	$(document).on("click", ".s31-addToCart-mobile", function() {
		$(this).find(".s31-loadingIco").show();
		$(this).find(".s31-cartWhite").hide();
	});

})(jQuery);

(function ($) {
	'use strict';

	$.fn.extend({
		addToCartWholesale: function () {}
	});

	var timeout = {};

	var addWholesale = function($this) {
		var href = $this.data('action');
		var product = $this.data('product');
		var simpleProduct = $this.data('simple');
		var token = $this.data('token');

		var variants = {};
		$('input[data-product='+product+']').each(function (e,d) {
			variants[$(d).data("variant")] = $(d).val();
		})

		var payload = {
			'product': product,
			'simpleProduct': simpleProduct,
			'variants': variants,
			'_token': token
		};

		$.ajax({
			method: 'POST',
			dataType: 'json',
			contentType: 'application/json',
			data: JSON.stringify(payload),
			cache: false,
			url: href,
			success: function (response) {
				$.each(variants, function (key, message) {
					$('#sylius-cart-validation-error-'+key).hide();
					$('#sylius-cart-validation-error-'+key).html('');
				});
				if (typeof response.total !== 'undefined') {
					$('#s31-article-summary-'+product+" .s31-article-summary-total-quantity").html(response.total.quantity);
					$('#s31-article-summary-'+product+" .s31-article-summary-total-price-with-tax").html(response.total.price);
					$('#s31-article-summary-'+product+" .s31-article-summary-total-price-without-tax").html(response.total.price_tax);
				}
				if (typeof response.cart !== 'undefined') {
					$("#s31-cart-layer, #s31-cart-layer-mobile").html(response.cart);
				}
				if (typeof response.errors !== 'undefined') {
					$.each(response.errors, function (key, message) {
						$('#sylius-cart-validation-error-'+key).html(message.join(','));
						$('#sylius-cart-validation-error-'+key).show();
					});
				}
			},
			error: function (jqXHR) {
				$.each(variants, function (key, message) {
					$('#sylius-cart-validation-error-'+key).hide();
					$('#sylius-cart-validation-error-'+key).html('');
				});
				var response = jqXHR.responseJSON;
				if (typeof response !== 'undefined' && typeof response.errors !== 'undefined') {
					$.each(response.errors, function (key, message) {
						$('#sylius-cart-validation-error-'+key).html(message.join(','));
						$('#sylius-cart-validation-error-'+key).show();
					});
				}
			}
		});
	};

	$(document).on("input", "input[name='sylius_add_to_cart_wholesale']", function (e) {
		e.preventDefault();
		var $this = $(this);
		var product = $this.data('product');
		clearTimeout(timeout[product]);

		timeout[product] = setTimeout(function() {
			addWholesale($this);
		}, 700);
	});


	$(document).on("change", "select[id^='sylius_edit_to_cart_cartItem_variant_']", function () {
		var url = $(this).data("url")
		var idProduct = $(this).data("id");
		//var idItem = $("input[name='cart-item-id-"+idProduct+"']").val();
		var idItem = $(this).data("itemid");

		var payload = {
			'velikost' : this.value,
			'idProduct' : idProduct,
			'idItem' : idItem
		};

		$.ajax({
			method: 'POST',
			dataType: 'json',
			contentType: 'application/json',
			data: JSON.stringify(payload),
			cache: false,
			url: url,
			success: function (response) {
				window.location.href = "/cart/";
			},
		});
	});

	$(document).on("change", "input[name^='add_one_product_to_cart_']", function (e) {
		e.preventDefault();
		e.stopPropagation();

		var idProduct = $(this).val();
		var idItem = $(this).data("itemid");
		var hasoption = $(this).data("hasoption");
		var isfirst = $(this).data("isfirst");

		if(isfirst) {

		}
		else if(hasoption == 0) {
			// update variant in cart
			var variantId = $(this).data("variantid");
			var variantCode = $(this).data("variantcode");
			var url = $(this).data("url");

			var payload = {
				'idItem' : idItem,
				'idProduct' : idProduct,
				'variantId' : variantId,
				'variantCode' : variantCode
			};

			$.ajax({
				method: 'POST',
				dataType: 'json',
				contentType: 'application/json',
				data: JSON.stringify(payload),
				cache: false,
				url: url,
				success: function (response) {
					window.location.href = "/cart/";
				},
			});
		}
		else {
			$('[id^=sylius_cartItem_other_variant_]').prop("disabled", true);
			$("#sylius_cartItem_other_variant_" + idProduct + "_" + idItem).prop("disabled", false);
		}

		$(".div_other_variant_" + idItem).hide();
		$("#div_other_variant_" + idProduct).show();
	});

	$("[id^='sylius_cartItem_other_variant_']").each(function () {
		var id = $(this).data("id");
		var itemid = $(this).data("itemid");

		$("<option value='0'> </option>").insertBefore($('#sylius_cartItem_other_variant_' + id + "_" + itemid + ' option:first-child'));
		$("#sylius_cartItem_other_variant_" + id + "_" + itemid + " option[value=0]").attr('selected', 'selected');
	});

	$(document).on("change", "[id^='sylius_cartItem_other_variant']", function() {
		var url = $(this).data("url");
		var idProduct = $(this).data("id");
		//var idItem = $("input[name='cart-item-id']").val();
		var idItem = $(this).data("itemid");
		var velikost = $(this).val();

		if(velikost == 0){
			return false;
		}

		var payload = {
			'velikost' : this.value,
			'idProduct' : idProduct,
			'idItem' : idItem
		};

		$.ajax({
			method: 'POST',
			dataType: 'json',
			contentType: 'application/json',
			data: JSON.stringify(payload),
			cache: false,
			url: url,
			success: function (response) {
				window.location.href = "/cart/";
			},
		});
	});

	$(document).on("change", "input[name^='add_variant_to_cart_']", function() {
		var idItem = $(this).data("itemid");
		var idProduct = $(this).val();
		var variantId = $(this).data("variantid");
		var variantCode = $(this).data("variantcode");
		var variantPrice = $(this).data("variantprice");
		var url = $(this).data("url");

		var payload = {
			'idItem' : idItem,
			'idProduct' : idProduct,
			'variantId' : variantId,
			'variantCode' : variantCode,
			'variantPrice': variantPrice,
		};

		$.ajax({
			method: 'POST',
			dataType: 'json',
			contentType: 'application/json',
			data: JSON.stringify(payload),
			cache: false,
			url: url,
			success: function (response) {
				window.location.href = "/cart/";
			},
		});
	});

	if (typeof selectSizeInSelectGift != 'undefined') {
		selectSizeInSelectGift();
	}

})(jQuery);

document.addEventListener('s31shop_cart_event_added', function (e) {
	if (typeof dataLayer !== 'undefined') {
		dataLayer.push(e.detail);
	}
});


function isEmbroideryTextValid(element) {
	var noErrors = true

	$(".s31-embroideryInput1").each(function() {
		if ($.trim($(this).val()) == "") {
			if (noErrors) {
				if ($(this).is(":visible")){
					$('html, body').animate({
						scrollTop: $(this).offset().top - 110
					}, 300);
					$(this).parent().addClass("error");
					$(this).parent().find(".alert").remove();
					$(this).parent().append(embErrorMessage);
				} else{
					$('html, body').animate({
						scrollTop: $(this).parent().parent().prev().offset().top - 110
					}, 300);
					$(this).parent().parent().prev().find(".field").addClass("error");
					$(this).parent().parent().prev().find(".field").find(".alert").remove();
					$(this).parent().parent().prev().find(".field").append(embErrorMessageSymbol);
				}
				
			}
			
			noErrors = false;
		}
	});

	return noErrors;
};

function selectSizeInSelectGift() {
	$("[id^='sylius_edit_to_cart_cartItem_variant_']").each(function () {
		var id = $(this).data("id");
		var itemid = $(this).data("itemid");

		if ($("#gift_select_variant_" + id + "_" + itemid).length) {
			var select_value = $("#gift_select_variant_" + id + "_" + itemid).val();
			$("#sylius_edit_to_cart_cartItem_variant_" + id + "_" + itemid + " option[value="+select_value+"]").attr('selected', 'selected');
		}
	});
}

