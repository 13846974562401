$(function () {

	$(document).on("click", ".s31-showDescriptionCont", function () {
		var thisEl = $(this);
		var buttonEl = $(this).find(".s31-showDescription");
		
		if (buttonEl.hasClass( "s31-opened" )) {
			window.setTimeout(function() {
				thisEl.prev().removeClass("s31-fadeOut");
			}, 300);
		} else {
			buttonEl.prev().addClass("s31-fadeOut");
		}
		buttonEl.toggleClass("s31-opened");
		buttonEl.prev().toggleClass("s31-opened");
	});

});

const topBannerCounter = function() {
	const bannerCountDownDate = new Date(bannerOdpocetDo).getTime();

	let interval = setInterval(function() {
		let now = new Date().getTime();
		let distance = bannerCountDownDate - now;
		let days = Math.floor(distance / (1000 * 60 * 60 * 24));
		let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		let seconds = Math.floor((distance % (1000 * 60)) / 1000);
		let dotsEl = '<span class="dots"> | </span>';

		let daysWord = cmsTimerDaysMore
		if (days == 1) {
			daysWord = cmsTimerDay
		} else if (days > 1 && days < 5) {
			daysWord = cmsTimerDays
		}

		let hoursWord = cmsTimerHoursMore
		if (hours == 1) {
			hoursWord = cmsTimerHour
		} else if (hours > 1 && hours < 5) {
			hoursWord = cmsTimerHours
		}

		let minutesWord = cmsTimerMinutesMore
		if (minutes == 1) {
			minutesWord = cmsTimerMinute
		} else if (minutes > 1 && minutes < 5) {
			minutesWord = cmsTimerMinutes
		}

		let secondsWord = cmsTimerSecondsMore
		if (seconds == 1) {
			secondsWord = cmsTimerSecond
		} else if (seconds > 1 && seconds < 5) {
			secondsWord = cmsTimerSeconds
		}

		if (days > 0) {
			document.getElementById("bannerCounterDays").innerHTML = days + ' ' + daysWord +  dotsEl;
		} else {
			document.getElementById("bannerCounterDays").innerHTML = "";
		}
		if (days > 0 || hours > 0) {
			document.getElementById("bannerCounterHours").innerHTML = hours + ' ' + hoursWord +  dotsEl;
		} else {
			document.getElementById("bannerCounterHours").innerHTML = "";
		}
		if (days > 0 || hours > 0 || minutes > 0) {
			document.getElementById("bannerCounterMinutes").innerHTML = minutes + ' ' + minutesWord +  dotsEl;
		} else {
			document.getElementById("bannerCounterMinutes").innerHTML = "";
		}
		document.getElementById("bannerCounterSeconds").innerHTML = '<span class="s31-secondsblock">' + seconds + '</span> ' + secondsWord;

		if (distance < 0) {
			clearInterval(interval);
			document.getElementById("s31-banner-counter").innerHTML = "";
		}
	}, 1000);
}