$(function() {
    if ((isCarLeaversAvailable) && (newsletterPopupCount != 3)) {
        if (typeof ouibounce != "undefined") {
            var _ouibounce = ouibounce(document.getElementById('ouibounce-modal'), {
                timer: 0,
                sitewide: true,
                callback: function() { 
                    console.log('ouibounce fired!');
                    $("#ouibounce-modal").modal("show");
                }
            });
        }
    }
});