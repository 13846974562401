var newsletterPopupCount = parseInt(getCookie("newsletterPopupShow"));

$(function() {
    if (newsletterPopupCount < 3) {
        newsletterPopupCount++;
        $.cookie('newsletterPopupShow', newsletterPopupCount, { expires : 30, path: '/' });
    } else if (newsletterPopupCount == 3) {
        $("#s31-newsletterPopup").modal("show");
        $.cookie('newsletterPopupShow', "opened", { expires : 30, path: '/' });
    }
});