$(function () {
	//Matchmedia korespondující s mediaquaries pro bootstrap

	if (mqMENUdesktop.matches) {

		$('.s31-mainmenu-container').addClass('s31-hover-available');

		// Rozbalování a skrývání cart layeru při kliku na košík v headeru
		$('#cart-button').on("click", function (e) {
			var container = $(".s31-cart-layer-inside");
			if (!container.is(e.target) && container.has(e.target).length === 0) {

				var elementToCheck = e.target;
				if (elementToCheck.parentElement.tagName.toLowerCase() == "svg") {
					elementToCheck = elementToCheck.parentElement.parentElement;
				} else if (elementToCheck.parentElement.tagName.toLowerCase() == "a") {
					elementToCheck = elementToCheck.parentElement;
				}

				if (elementToCheck.tagName.toLowerCase() == "a" && (" " + elementToCheck.className + " ").replace(/[\n\t]/g, " ").indexOf(" s31-cart-layer-delete ") > -1) {
					return;
				}

				$("#cart-button").css('z-index', '999999999');
				$("#account-button").css('z-index', '99999999');

				if ($('#cart-button').hasClass("s31-activeBlock")) {
					$(".s31-sub-menu-container-shadow").fadeOut(headerAnimationDuration);
					$(".s31-sub-menu-container-shadow").removeClass('s31-openedByHead');
				} else {
					$(".s31-sub-menu-container-shadow").fadeIn(headerAnimationDuration);
					$(".s31-sub-menu-container-shadow").addClass('s31-openedByHead');
				}

				$("#s31-cart-layer .s31-cart-layer-inside").slideToggle(headerAnimationDuration);
				$("#cart-button").toggleClass('s31-activeBlock');
				$("#account-layer").slideUp(headerAnimationDuration);
				$("#account-button").removeClass('s31-activeBlock');
			}
		});

		// Rozbalování a skrývání account layeru při kliku na účet v headeru
		$('#account-button').on("click", function (e) {
			var container = $("#account-layer");
			if (!container.is(e.target) && container.has(e.target).length === 0) {

				$("#cart-button").css('z-index', '9999999');
				$("#account-button").css('z-index', '99999999');

				if ($('#account-button').hasClass("s31-activeBlock")) {
					$(".s31-sub-menu-container-shadow").fadeOut(headerAnimationDuration);
					$(".s31-sub-menu-container-shadow").removeClass('s31-openedByHead');
				} else {
					$(".s31-sub-menu-container-shadow").fadeIn(headerAnimationDuration);
					$(".s31-sub-menu-container-shadow").addClass('s31-openedByHead');
				}

				$("#account-layer").slideToggle(headerAnimationDuration);
				$("#account-button").toggleClass('s31-activeBlock');

				$("#s31-cart-layer .s31-cart-layer-inside").slideUp(headerAnimationDuration);
				$("#cart-button").removeClass('s31-activeBlock');
			}
		});

	} else if (mqMENUtablet.matches) {

		// Rozbalování a skrývání cart layeru při kliku na košík v headeru
		$('#cart-button').on("click", function (e) {
			var container = $(".s31-cart-layer-inside");
			if (!container.is(e.target) && container.has(e.target).length === 0) {

				var elementToCheck = e.target;
				if (elementToCheck.parentElement.tagName.toLowerCase() == "svg") {
					elementToCheck = elementToCheck.parentElement.parentElement;
				} else if (elementToCheck.parentElement.tagName.toLowerCase() == "a") {
					elementToCheck = elementToCheck.parentElement;
				}

				if (elementToCheck.tagName.toLowerCase() == "a" && (" " + elementToCheck.className + " ").replace(/[\n\t]/g, " ").indexOf(" s31-cart-layer-delete ") > -1) {
					return;
				}

				$("#cart-button").css('z-index', '999999999');
				$("#account-button").css('z-index', '99999999');

				if ($('#cart-button').hasClass("s31-activeBlock")) {
					$(".s31-sub-menu-container-shadow").fadeOut(headerAnimationDuration);
					$(".s31-sub-menu-container-shadow").removeClass('s31-openedByHead');
				} else {
					$(".s31-sub-menu-container-shadow").fadeIn(headerAnimationDuration);
					$(".s31-sub-menu-container-shadow").addClass('s31-openedByHead');
				}

				$("#s31-cart-layer .s31-cart-layer-inside").slideToggle(headerAnimationDuration);
				$("#cart-button").toggleClass('s31-activeBlock');

				$("#account-layer").slideUp(headerAnimationDuration);
				$("#account-button").removeClass('s31-activeBlock');

				$(".s31-sub-menu-container").hide();
				$(".s31-sub-menu-level1").removeClass('s31-menu-hover');
			}
		});

		// Rozbalování a skrývání account layeru při kliku na účet v headeru
		$('#account-button').on("click", function (e) {
			var container = $("#account-layer");
			if (!container.is(e.target) && container.has(e.target).length === 0) {

				$("#cart-button").css('z-index', '9999999');
				$("#account-button").css('z-index', '99999999');

				if ($('#account-button').hasClass("s31-activeBlock")) {
					$(".s31-sub-menu-container-shadow").fadeOut(headerAnimationDuration);
					$(".s31-sub-menu-container-shadow").removeClass('s31-openedByHead');
				} else {
					$(".s31-sub-menu-container-shadow").fadeIn(headerAnimationDuration);
					$(".s31-sub-menu-container-shadow").addClass('s31-openedByHead');
				}

				$("#account-layer").slideToggle(headerAnimationDuration);
				$("#account-button").toggleClass('s31-activeBlock');

				$("#s31-cart-layer .s31-cart-layer-inside").slideUp(headerAnimationDuration);
				$("#cart-button").removeClass('s31-activeBlock');

				$(".s31-sub-menu-container").hide();
				$(".s31-sub-menu-level1").removeClass('s31-menu-hover');
			}
		});

		$('#s31-search-bar-button').on("click", function (e) {
//				$(".s31-sub-menu-container-shadow").fadeOut(headerAnimationDurationMobile);
				$("#s31-search-bar").slideToggle(headerAnimationDurationMobile);
				$("#s31-mainmenu-container").fadeOut(headerAnimationDurationMobile);
				$("#s31-cart-layer-mobile").fadeOut(headerAnimationDurationMobile);
				$("#s31-search-bar-button").toggleClass('s31-activeBlock');
				$("#cart-button").removeClass('s31-activeBlock');
				$("#s31-mobile-menu-button").removeClass('s31-activeBlock');
				$("#account-layer").slideUp(headerAnimationDuration);
				$("#account-button").removeClass('s31-activeBlock');
			});


	} else if (mqMENUmobile.matches) {

		if (mqmd.matches) {

			// Rozbalování a skrývání cart layeru při kliku na košík v headeru
			$('#cart-button').on("click", function (e) {
				var container = $(".s31-cart-layer-inside");
				if (!container.is(e.target) && container.has(e.target).length === 0) {

					var elementToCheck = e.target;
					if (elementToCheck.parentElement.tagName.toLowerCase() == "svg") {
						elementToCheck = elementToCheck.parentElement.parentElement;
					} else if (elementToCheck.parentElement.tagName.toLowerCase() == "a") {
						elementToCheck = elementToCheck.parentElement;
					}

					if (elementToCheck.tagName.toLowerCase() == "a" && (" " + elementToCheck.className + " ").replace(/[\n\t]/g, " ").indexOf(" s31-cart-layer-delete ") > -1) {
						return;
					}

					$("#cart-button").css('z-index', '999999999');
					$("#account-button").css('z-index', '99999999');

					if ($('#cart-button').hasClass("s31-activeBlock")) {
						$(".s31-sub-menu-container-shadow").fadeOut(headerAnimationDuration);
						$(".s31-sub-menu-container-shadow").removeClass('s31-openedByHead');
					} else {
						$(".s31-sub-menu-container-shadow").fadeIn(headerAnimationDuration);
						$(".s31-sub-menu-container-shadow").addClass('s31-openedByHead');
					}

					$("#s31-cart-layer .s31-cart-layer-inside").slideToggle(headerAnimationDuration);
					$("#cart-button").toggleClass('s31-activeBlock');
					$("#s31-search-bar").slideUp(headerAnimationDurationMobile);


					$("#account-layer").slideUp(headerAnimationDuration);
					$("#account-button").removeClass('s31-activeBlock');

					$(".s31-sub-menu-container").hide();
					$(".s31-sub-menu-level1").removeClass('s31-menu-hover');
				}
			});

		} else {

			// Rozbalování a skrývání cart layeru při kliku na košík v headeru
			$('#cart-button').on("click", function (e) {
				var elementToCheck = e.target;
				if (elementToCheck.parentElement.tagName.toLowerCase() == "svg") {
					elementToCheck = elementToCheck.parentElement.parentElement;
				} else if (elementToCheck.parentElement.tagName.toLowerCase() == "a") {
					elementToCheck = elementToCheck.parentElement;
				}

				if (elementToCheck.tagName.toLowerCase() == "a" && (" " + elementToCheck.className + " ").replace(/[\n\t]/g, " ").indexOf(" s31-cart-layer-delete ") > -1) {
					return;
				}

				$("#cart-button").css('z-index', '999999999');
				$("#account-button").css('z-index', '99999999');

				if ($('#cart-button').hasClass("s31-activeBlock")) {
					$(".s31-sub-menu-container-shadow").fadeOut(headerAnimationDurationMobile);
				} else {
					$(".s31-sub-menu-container-shadow").fadeIn(headerAnimationDurationMobile);
				}
				$("#s31-cart-layer-mobile .s31-cart-layer-inside").fadeToggle(headerAnimationDurationMobile);
				$("#s31-mainmenu-container").fadeOut(headerAnimationDurationMobile);
				$("#s31-search-bar").slideUp(headerAnimationDurationMobile);
				$("#cart-button").toggleClass('s31-activeBlock');
				$("#s31-search-bar-button").removeClass('s31-activeBlock');
				$("#s31-mobile-menu-button").removeClass('s31-activeBlock');
				$("#account-layer").slideUp(headerAnimationDuration);
				$("#account-button").removeClass('s31-activeBlock');
			});
		}

		$('#s31-search-bar-button').on("click", function (e) {
			$("#s31-search-bar").slideToggle(headerAnimationDurationMobile);
			$("#s31-mainmenu-container").fadeOut(headerAnimationDurationMobile);
			$("#s31-cart-layer-mobile .s31-cart-layer-inside").fadeOut(headerAnimationDurationMobile);
			$("#s31-cart-layer .s31-cart-layer-inside").slideUp(headerAnimationDuration);
			$("#s31-search-bar-button").toggleClass('s31-activeBlock');
			$("#cart-button").removeClass('s31-activeBlock');
			$("#s31-mobile-menu-button").removeClass('s31-activeBlock');
			$("#account-layer").slideUp(headerAnimationDuration);
			$("#account-button").removeClass('s31-activeBlock');
		});


		// Rozbalování a skrývání account layeru při kliku na účet v headeru
		$('#account-button').on("click", function (e) {
			var container = $("#account-layer");
			if (!container.is(e.target) && container.has(e.target).length === 0) {
				$("#cart-button").css('z-index', '9999999');
				$("#account-button").css('z-index', '99999999');
				if ($('#account-button').hasClass("s31-activeBlock")) {
					$(".s31-sub-menu-container-shadow").fadeOut(headerAnimationDuration);
					$(".s31-sub-menu-container-shadow").removeClass('s31-openedByHead');
				} else {
					$(".s31-sub-menu-container-shadow").fadeIn(headerAnimationDuration);
					$(".s31-sub-menu-container-shadow").addClass('s31-openedByHead');

				}
				$("#account-layer").slideToggle(headerAnimationDuration);
				$("#account-button").toggleClass('s31-activeBlock');

				$("#s31-search-bar").slideUp(headerAnimationDurationMobile);
				$("#s31-mainmenu-container").fadeOut(headerAnimationDurationMobile);

				$("#s31-cart-layer-mobile .s31-cart-layer-inside").fadeOut(headerAnimationDurationMobile);
				$("#s31-search-bar-button").removeClass('s31-activeBlock');
				$("#s31-cart-layer .s31-cart-layer-inside").slideUp(headerAnimationDuration);
				$("#cart-button").removeClass('s31-activeBlock');

				$("#cart-button").removeClass('s31-activeBlock');
				$("#s31-mobile-menu-button").removeClass('s31-activeBlock');
			}
		});
	}

	// Ostatní JS společné pro všechny zařízení
	//----------------------------------------------------------------------

	// Obarvování a animace na vyhledávání v hlavičce
	$(document).on("focus, click", ".s31-search-input", function () {
		$('.s31-search-button').addClass("s31-search-focus");
		$('.s31-search-input:not(.tt-hint)').addClass("s31-search-focus");
	});
	$('.s31-search-input').blur(function () {
		$('.s31-search-button').removeClass("s31-search-focus");
		$('.s31-search-input:not(.tt-hint)').removeClass("s31-search-focus");
	});

	// Skrývání header layerů a pozadí při kliku na stín pod menu a layery
	$('.s31-sub-menu-container-shadow').on("click", function () {
		$(".s31-sub-menu-container-shadow").fadeOut(headerAnimationDuration);
		$("#account-layer").slideUp(headerAnimationDuration);
		$("#s31-cart-layer .s31-cart-layer-inside").slideUp(headerAnimationDuration);
		$("#s31-cart-layer-mobile .s31-cart-layer-inside").slideUp(headerAnimationDuration);
		$("#account-button").removeClass('s31-activeBlock');
		$("#cart-button").removeClass('s31-activeBlock');
		$(".s31-sub-menu-container").hide();
		$(".s31-sub-menu-level1").removeClass('s31-menu-hover');
	});

	// Skrytí accountu a cartu při kliku na input
	$('.s31-search-input').on("click", function () {
		$(".s31-sub-menu-container-shadow").fadeIn(headerAnimationDuration);
		$(".s31-sub-menu-container-shadow").addClass('s31-openedByHead');
		$("#account-layer").slideUp(headerAnimationDuration);
		$("#account-button").removeClass('s31-activeBlock');
		$("#s31-cart-layer .s31-cart-layer-inside").slideUp(headerAnimationDuration);
		$("#s31-cart-layer-mobile .s31-cart-layer-inside").slideUp(headerAnimationDuration);
		$("#cart-button").removeClass('s31-activeBlock');
	});

	$(document).addToCart('form[name="sylius_add_to_cart"]');
	$(document).addToCartWholesale('input[name="sylius_add_to_cart_wholesale"]');
	$(document).quantitySelector('.s31-quantity-selector');
	$(document).removeFromCart('.s31-cart-removeButt-ajax');
	$(document).removeFromCart('.s31-cart-layer-delete');

	$('#criteria_search_value, #criteria_search_value_mobile').typeahead({
		classNames: {
			menu: 's31-autocomplete'
		},
		hint: true,
		highlight: true,
		minLength: 1,
	},
			{
				name: 'autocomplete',
				display: 'value',
				source: autocompleteSource,
				templates: {
					header: function (data) {
						var fragment = document.createDocumentFragment();

						var head = document.createElement("div");
						head.className = "s31-autocomplete-head";

						if (autocompleteSourceResult.categories.length != 0) {
                            var headName = document.createElement("div");
                            headName.className = "s31-autocomplete-articleCount";
                            headName.innerText = categoryAlgolia;
                            head.appendChild(headName);

                            generateCategoriesTemplate(data, head);
						}

						if (autocompleteSourceResult.cms.length != 0) {
                            var headName = document.createElement("div");
                            headName.className = "s31-autocomplete-articleCount";
                            headName.innerText = pagesAlgolia;
                            head.appendChild(headName);

                            generateCmsTemplate(data, head);
						}

						if (autocompleteSourceResult.productsFulltext.length != 0) {
							var headName = document.createElement("div");
							headName.className = "s31-autocomplete-articleCount";
							headName.innerText = productsFulltextAlgolia + ' ' + data.query;
							head.appendChild(headName);

							generateproductsFulltextTemplate(data, head);
						}

						fragment.appendChild(head);

						var productCount = document.createElement("div");
						productCount.className = "s31-autocomplete-articleCount";
						productCount.innerText = productAlgolia + " (" + autocompleteSourceResult.totalCount + "x)";

						fragment.appendChild(productCount);

						return fragment;
					},
					suggestion: function (data) {
						if (data.original_price > data.price) {
							return "<a class='s31-autocomplete-article' href='" + data.url + "' data-insights-object-id='" + data.id + "' data-insights-position='" + data.position + "' data-insights-query-id='" + autocompleteSourceResult.products_query_id + "'><img src='" + data.image + "' alt='" + data.value + "' class='s31-article-mainImage'><div class='s31-autocomplete-articleDesc'><p>" + data.value + "</p><span class='s31-autocomplete-price-original'>" + data.original_price + "</span> <span>" + data.price + "</span></div></a>";
						} else {
							return "<a class='s31-autocomplete-article' href='" + data.url + "' data-insights-object-id='" + data.id + "' data-insights-position='" + data.position + "' data-insights-query-id='" + autocompleteSourceResult.products_query_id + "'><img src='" + data.image + "' alt='" + data.value + "' class='s31-article-mainImage'><div class='s31-autocomplete-articleDesc'><p>" + data.value + "</p><span>" + data.price + "</span></div></a>";
						}
					},

					footer: function () {
						var remainingCount = autocompleteSourceResult.totalCount - autocompleteSourceResult.products.length;

						if (remainingCount > 0) {
							return "<div class='s31-autocomplete-showAll'><a class='submitSearch s31-button-grey-cart'>" + allProductsAlgolia + " (" + autocompleteSourceResult.totalCount + ")</a></div>";
						}

						return "";
					},
					notFound: function(data) {
                        if (autocompleteSourceResult.categories.length != 0 || autocompleteSourceResult.productsFulltext.length != 0) {
                        	var fragment = document.createDocumentFragment();

                        	var head = document.createElement("div");
                        	head.className = "s31-autocomplete-head";


							if (autocompleteSourceResult.categories.length != 0) {
								var headName = document.createElement("div");
								headName.className = "s31-autocomplete-articleCount";
								headName.innerText = categoryAlgolia;
								head.appendChild(headName);

								generateCategoriesTemplate(data, head);
							}

							if (autocompleteSourceResult.productsFulltext.length != 0) {
								var headName = document.createElement("div");
								headName.className = "s31-autocomplete-articleCount";
								headName.innerText = productsFulltextAlgolia + ' ' + data.query;
								head.appendChild(headName);

								generateproductsFulltextTemplate(data, head);
							}

							fragment.appendChild(head);
							return fragment;
						}
					}
				}
			}
	);

	var generateCategoriesTemplate = function (data, node) {
        for (var i in autocompleteSourceResult.categories) {
            var category = autocompleteSourceResult.categories[i];
            	categoryElement = document.createElement("div");

            categoryElement.className = "s31-autocomplete-category";

            if (category.hasOwnProperty("path") && category.path.length > 0) {
                for (var ii in category.path) {
                    var categoryInPath = category.path[ii];
                    var path = document.createElement("a");
                    path.href = categoryInPath.url;
                    path.innerText = categoryInPath.name;

                    categoryElement.appendChild(path);

                    categoryElement.appendChild(document.createTextNode(" > "));
                }
            }

            var categoryLink = document.createElement("a");
			var position = parseInt(i) + 1;
            categoryLink.href = category.url;
            categoryLink.innerText = category.value;
			categoryLink.setAttribute("data-insights-object-id", category.id);
			categoryLink.setAttribute("data-insights-position", position);
			categoryLink.setAttribute("data-insights-query-id", autocompleteSourceResult.categories_query_id);

            categoryElement.appendChild(categoryLink);
            autocompleteHighlight({ node: categoryElement, pattern: data.query });

            node.appendChild(categoryElement);
        }
	};

	var generateCmsTemplate = function (data, node) {
        for (var i in autocompleteSourceResult.cms) {
            var cms = autocompleteSourceResult.cms[i];
            	cmsElement = document.createElement("div");
				cmsElement.className = "s31-autocomplete-cms";

            if (cms.hasOwnProperty("path") && cms.path.length > 0) {
                for (var ii in cms.path) {
                    var cmsInPath = cms.path[ii];
                    var path = document.createElement("a");
                    path.href = cmsInPath.url;
                    path.innerText = cmsInPath.name;

                    cmsElement.appendChild(path);

                    cmsElement.appendChild(document.createTextNode(" > "));
                }
            }

            var cmsLink = document.createElement("a");
			var position = parseInt(i) + 1;
            cmsLink.href = cms.url;
            cmsLink.innerText = cms.value;
			cmsLink.setAttribute("data-insights-object-id", cms.id);
			cmsLink.setAttribute("data-insights-position", position);

            cmsElement.appendChild(cmsLink);
            autocompleteHighlight({ node: cmsElement, pattern: data.query });

            node.appendChild(cmsElement);
        }
	};

	var generateproductsFulltextTemplate = function (data, node) {
        for (var i in autocompleteSourceResult.productsFulltext) {
            var productFulltext = autocompleteSourceResult.productsFulltext[i];
			productFulltextElement = document.createElement("div");
			productFulltextElement.className = "s31-autocomplete-product";

			if (productFulltext.original_price > productFulltext.price) {
				productFulltextElement.insertAdjacentHTML("beforeend", "<a class='s31-autocomplete-article' href='" + productFulltext.url + "' data-insights-object-id='" + productFulltext.id + "' data-insights-position='" + productFulltext.position + "' data-insights-query-id='" + autocompleteSourceResult.products_query_id + "'><img src='" + productFulltext.image + "' alt='" + productFulltext.value + "' class='s31-article-mainImage'><div class='s31-autocomplete-articleDesc'><p>" + productFulltext.value + "</p><span class='s31-autocomplete-price-original'>" + productFulltext.original_price + "</span> <span>" + productFulltext.price + "</span></div></a>");
			} else {
				productFulltextElement.insertAdjacentHTML("beforeend", "<a class='s31-autocomplete-article' href='" + productFulltext.url + "' data-insights-object-id='" + productFulltext.id + "' data-insights-position='" + productFulltext.position + "' data-insights-query-id='" + autocompleteSourceResult.products_query_id + "'><img src='" + productFulltext.image + "' alt='" + productFulltext.value + "' class='s31-article-mainImage'><div class='s31-autocomplete-articleDesc'><p>" + productFulltext.value + "</p><span>" + productFulltext.price + "</span></div></a>");
			}

			autocompleteHighlight({ node: productFulltextElement, pattern: data.query });

            node.appendChild(productFulltextElement);
        }
	};

	$('#criteria_search_value, #criteria_search_value_mobile').bind('typeahead:open', function () {
		$(".s31-sub-menu-container-shadow").fadeIn(headerAnimationDuration);
		$(".s31-sub-menu-container-shadow").addClass('s31-openedByHead');
	});

	$('#criteria_search_value, #criteria_search_value_mobile').parents("form").on("submit", function (e) {
		e.preventDefault();
		var term, url;


		term = $('#criteria_search_value').val();
		if (term == "") {
			term = $('#criteria_search_value_mobile').val();
		}

		url = this.action + "/" + term;

		location.href = url;

		return false;
	});

	$(document).on("click", ".twitter-typeahead .submitSearch", function (e) {
		e.preventDefault();

		$('#criteria_search_value, #criteria_search_value_mobile').parents("form").submit();
	});

	$(document).on("click", "#s31-cookie-check", function () {
		$.cookie('cookieCheck', 'agreed', {expires: 2000, path: '/'});
		$('.s31-cookie-line-close').addClass('s31-cookie-lineHidden');
		setTimeout(function () {
			$('.s31-cookie-line-close').slideUp('150');
		}, 50);
	});

	$(document).on("click", "#s31-covid-check", function () {
		$.cookie('covidCheck', 'agreed', {expires: 2000, path: '/'});
		$('.s31-covid-line').addClass('s31-cookie-lineHidden');
		setTimeout(function () {
			$('.s31-covid-line').slideUp('150');
		}, 50);
	});

	$(document).on("click", ".s31-topBannerLine-close", function () {
		$.cookie('topBannerLineHidden', 'yes', {expires: 1, path: '/'});
		$('.s31-bannerCont.s31-cookie-line').addClass('s31-cookie-lineHidden');
	});

	$(document).on("click", "#s31-active-code", function () {
		$.cookie('codeCheck', 'agreed', {expires: 2000, path: '/'});
		$('.s31-code-line').addClass('s31-cookie-lineHidden');
		setTimeout(function () {
			$('.s31-code-line').slideUp('150');
		}, 50);
	});

	$(document).on("click", ".s31-promotion-close", function () {
		$.cookie('promotionCheck', 'agreed', {expires: 2000, path: '/'});
		$('.s31-promotion-line').addClass('s31-cookie-lineHidden');
	});

	// If there is no search term, search won't be submitted
    $(document).on('click', '.s31-search-button', function () {
		var searchInput = $(this).parents('form').find('#criteria_search_value');
		var searchInputMobile = $(this).parents('form').find('#criteria_search_value_mobile');

        if (searchInput.val() === "" || searchInputMobile.val() === "") {
        	// Animation class
        	if ($(this).parents('.s31-search').length === 1) {
                $(this).parents('.s31-search').addClass('s31-error-animated-search-bar');
				setTimeout(function () {
					$(".s31-error-animated-search-bar").removeClass('s31-error-animated-search-bar');
				}, 820);
			}
            return false;
        }
    });

    // Deleting animation class on focus on search input
	$(document).on('focus', '#criteria_search_value', function () {
        if ($(this).parents('.s31-search').length === 1) {
            $(this).parents('.s31-search').removeClass('s31-error-animated-search-bar');
        }
    });

	if ($("#cartMergeModal").length == 1) {
        $("#cartMergeModal").modal("show");
	}

	$('#sylius-api-login').accountApiLogin({
		method: 'POST',
		throttle: 500,
	});

	var currentTitle =  document.title;
	var missYouTitle =  weMissYouTitle;
	var titleInterval;
	$(window).on("blur focus", function(e) {
		var prevType = $(this).data("prevType");

		if (prevType != e.type) {
			switch (e.type) {
				case "blur":
					if (!titleInterval) {
						titleInterval = setInterval(function () {
							if (document.title == currentTitle) {
								document.title = missYouTitle;
							} else {
								document.title = currentTitle;
							}
						}, 2000);
					}
					break;
				case "focus":
					document.title = currentTitle;
					clearInterval(titleInterval);
    				titleInterval = 0;
					break;
			}
		}

		$(this).data("prevType", e.type);
	})
});

var tagsToReplace = {
	'&': '&amp;',
	'<': '&lt;',
	'>': '&gt;'
};

function replaceTag(tag) {
	return tagsToReplace[tag] || tag;
}

function safe_tags_replace(str) {
	return str.replace(/[&<>]/g, replaceTag);
}