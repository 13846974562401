$(document).ready(function () {

    $("#s31shop-watchdog").on("submit", function(e) {
        var form = $(this);

        e.preventDefault();
        form.find("input[type='submit']").parent().addClass("s31-loader-spinning");

        $.ajax({
            method: 'POST',
            data: $(this).serialize(),
            cache: false,
            url: this.action,
            success: function (response) {
                if (response.hasOwnProperty("redirect")) {
                    location.href = response.redirect;
                } else {
                    location.reload();
                }
            },
            error: function (jqXHR) {
                var validationMessage = '';

                form.find(".s31shop-watchdog-validation-error").addClass("d-none");

                if (jqXHR.responseJSON !== "") {
                    var response = jqXHR.responseJSON;

                    $.each(response.errors, function (key, message) {
                        validationMessage += message;
                    });

                    form.find(".s31shop-watchdog-validation-error").text(validationMessage).removeClass("d-none");
                    form.find(".s31-article-watchdog-input").addClass("error");
                } else {
                    form.find(".s31shop-watchdog-validation-error").text("Přidání se nezdařilo, zkuste to, prosím, později.").removeClass("d-none");
                }
            },
            complete: function() {
                form.find("input[type='submit']").parent().removeClass("s31-loader-spinning");
            }
        });

        return false;
    });
});
