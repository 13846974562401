(function ( $ ) {
    'use strict';

    $.fn.extend({
        addressBook: function () {
            var element = $(this);
            var select = element.find('.address-book-select');

            if (typeof select.selectpicker === "function") {
                select.selectpicker({liveSearch: true, noneSelectedText: "Zvolit adresu"})
            }

            select.on("change", function (e) {
                var choice = $(this).find("option:selected");

                var provinceCode = choice.data()['provinceCode'],
                    provinceName = choice.data()['provinceName'],
                    provinceContainer = $(this).parent().find(".province-container").get(0);

                $.each(element.find('input, select'), function (key, input) {
                    if ($(input).prop("name") == "sylius_checkout_address[customer][email]" ||
                        $(input).prop("name") == "sylius_checkout_address[addressType]" ||
                        $(input).prop("name") == "sylius_checkout_address[billingAddress][countryCode]" ||
                        $(input).prop("name") == "sylius_checkout_address[shippingAddress][countryCode]" ||
                        input.classList.contains("address-book-select"))
                    {
                        return;
                    }

                    if ($(input).is(":disabled") === true) {
                        return;
                    }

                    $(input).val('');
                });

                if (element.attr("id") == "s31-billing-address") {
                    if (choice.data("crn") != "" || choice.data("vat-id") != "") {
                        $("input[name='sylius_checkout_address[addressType]'][value='1']").prop("checked", true).change();
                    } else {
                        $("input[name='sylius_checkout_address[addressType]'][value='0']").prop("checked", true).change();
                    }
                }

                $.each(choice.data(), function (property, value) {
                    var field = findByName(property);

                    if (field.is(":disabled") === true) {
                        return;
                    }

                    if (-1 !== property.indexOf('countryCode')) {
                        field.val(value).change();

                        if (typeof provinceContainer !== "undefined") {
                            var exists = setInterval(function () {
                                var provinceCodeField = findByName('provinceCode');
                                var provinceNameField = findByName('provinceName');

                                if (!provinceContainer.hasAttribute("data-loading")) {
                                    if (0 !== provinceCodeField.length && ('' !== provinceCode || undefined != provinceCode)) {
                                        provinceCodeField.val(provinceCode);

                                        clearInterval(exists);
                                    } else if (0 !== provinceNameField.length && ('' !== provinceName || undefined != provinceName)) {
                                        provinceNameField.val(provinceName);

                                        clearInterval(exists);
                                    }
                                }
                            }, 100);
                        }
                    } else {
                        field.val(value);
                    }
                });
            });

            var parseKey = function (key) {
                return key.replace(/(_\w)/g, function (words) {return words[1].toUpperCase()});
            };
            var findByName = function (name) {
                return element.find('[name$=' + parseKey(name) + '\\]]');
            };
        }
    });
})( jQuery );
