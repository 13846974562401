(function ( $ ) {
    'use strict';

    $.fn.extend({
        apiLogin: function (apiSettings) {
            var element = $(this);
            var apiSettings = apiSettings || {};
            var passwordField = element.find('input[type=\'password\']');
            var emailField = element.find('input[type=\'email\']');
            var csrfTokenField = element.find('input[type=\'hidden\']');
            var signInButton = element.find('.button');
            var validationField = passwordField.parent().find('.red.label');
            var checkValidationField = emailField.parent().next('.red.label');
            var passwordRow = element.find(".s31-password-row");
            var checkTimeout = null;

            emailField.on("keydown", function(e) {
                if (e.keyCode == 13) {
                    e.preventDefault();

                    checkEmail(emailField.val(), emailField.data("url"));
                }
            });

            emailField.on("keyup", function(e) {
                clearTimeout(checkTimeout);

                if (e.keyCode == 13) {
                    return;
                }

                checkTimeout = setTimeout(function() {
                    checkEmail(emailField.val(), emailField.data("url"));
                }, 400);
            });

            passwordField.on("keydown", function(e) {
                if (e.keyCode == 13) {
                    e.preventDefault();

                    signInButton.click();
                }
            });

            signInButton.on("click", function(e) {
                e.preventDefault();

                if (emailField.val() == '') {
                    emailField.parent().parent().addClass("error");
                    checkValidationField.removeClass('d-none');
                    passwordRow.addClass("d-none");
                    checkValidationField.html("Pro přihlášení je nutné zadat e-mail!");
                    return;
                }

                if (passwordRow.is(":hidden")) {
                    checkEmail(emailField.val(), emailField.data("url"));
                } else {
                    var formData = {
                        _username: emailField.val(),
                        _password: passwordField.val()
                    };
                    formData[csrfTokenField.attr('name')] = csrfTokenField.val();

                    $.ajax({
                        method: apiSettings.method || "POST",
                        dataType: apiSettings.dataType || "json",
                        data: formData,
                        cache: false,
                        url: signInButton.data("url"),
                        success: function (response) {
                            passwordField.parent().removeClass("error");
                            element.remove();
                            location.reload();
                        },
                        error: function (jqXHR) {
                            passwordField.parent().addClass("error");
                            validationField.removeClass('d-none');

                            if (jqXHR.status == 401) {
                                validationField.html("Neplatné přihlašovací údaje.");
                            } else {
                                validationField.html(jqXHR.responseJSON.message);
                            }
                        }
                    });
                }
            });

            function checkEmail(email, url) {
                emailField.parent().parent().removeClass("error");
                checkValidationField.addClass('d-none');

                if (email == '') {
                    return;
                }

                $.ajax({
                    method: "GET",
                    dataType: "json",
                    data: { email: email },
                    cache: false,
                    url: url,
                    success: function (response) {
                        passwordField.parent().removeClass("error");
                        passwordRow.removeClass("d-none");
                        emailField.parent().parent().removeClass("error");
                        checkValidationField.addClass('d-none');

                        passwordField.focus();
                    },
                    error: function (jqXHR) {
                        emailField.parent().parent().addClass("error");
                        checkValidationField.removeClass('d-none');
                        passwordRow.addClass("d-none");

                        if (jqXHR.status == 404) {
                            checkValidationField.html("Pod tímto e-mailem není registrovaný žádný účet.");
                        } else {
                            checkValidationField.html(jqXHR.responseJSON.message);
                        }
                    }
                });
            }
        }
    });
})( jQuery );