$(function () {
	//Matchmedia korespondující s mediaquaries pro bootstrap


	if (mqMENUdesktop.matches) {

		// Zobrazení stínu pod menu a otevřených položek v hlavičce při najetí na submenu
		$('div[id^="s31-submenu-button-"],div[id*=" s31-submenu-button-"]').mouseover(function () {
			var thisElement = $(this);
			var menuId = $(this).attr("data-menuId");

			var menuTimeoutOpen1 = setTimeout(function () {
				$('#criteria_search_value').typeahead('close');
				$('.s31-search-button').removeClass("s31-search-focus");
				$('.s31-search-input:not(.tt-hint)').removeClass("s31-search-focus");
				$('div[id^="s31-submenu-button-"],div[id*=" s31-submenu-button-"]').removeClass('s31-menu-hover');
				$('div[id^="s31-submenu-container-"],div[id*=" s31-submenu-container-"]').hide();
				if (!thisElement.hasClass("s31-sub-menu-none")) {
					$('.s31-sub-menu-container-shadow').show();
					$('#s31-submenu-container-' + menuId).show();
					$('.s31-sub-menu-container-shadow').removeClass('s31-openedByHead');
				} else {
					$('.s31-sub-menu-container-shadow').hide();
				}
				thisElement.addClass('s31-menu-hover');
				$('#account-layer').slideUp(headerAnimationDuration);
				$('#s31-cart-layer .s31-cart-layer-inside').slideUp(headerAnimationDuration);
				$('#account-button').removeClass('s31-activeBlock');
				$('#cart-button').removeClass('s31-activeBlock');
			}, headerMenuTimeout);

			thisElement.mouseout(function () {
				clearTimeout(menuTimeoutOpen1);
			});
		});

		// Skrytí stínu a menu při sjetí ze submenu
		$('div[id^="s31-submenu-container-"],div[id*=" s31-submenu-container-"]').mouseout(function () {
			var thisElement = $(this);
			var menuId = $(this).attr("data-menuId");

			var menuTimeoutCLose = setTimeout(function () {
				$('.s31-sub-menu-container-shadow:not(.s31-openedByHead)').hide();
				$('#s31-submenu-container-' + menuId).hide();
				$('#s31-submenu-button-' + menuId).removeClass('s31-menu-hover');
			}, headerMenuTimeout);

			thisElement.mouseover(function () {
				clearTimeout(menuTimeoutCLose);
			});
		});


		// Skrytí stínu pod menu a otevřených položek v hlavičce při najetí na menu
		$('.s31-menu-pointLink').mouseover(function () {
			var thisElement = $(this);

			var menuTimeoutOpen2 = setTimeout(function () {
				$('#criteria_search_value').typeahead('close');
				$('.s31-search-button').removeClass("s31-search-focus");
				$('.s31-search-input:not(.tt-hint)').removeClass("s31-search-focus");
				$('div[id^="s31-submenu-button-"],div[id*=" s31-submenu-button-"]').removeClass('s31-menu-hover');
				thisElement.addClass('s31-menu-hover');
				$('#account-layer').slideUp(headerAnimationDuration);
				$('#s31-cart-layer .s31-cart-layer-inside').slideUp(headerAnimationDuration);
				$('#account-button').removeClass('s31-activeBlock');
				$('#cart-button').removeClass('s31-activeBlock');
				$('.s31-sub-menu-container-shadow:not(.s31-openedByHead)').hide();
			}, headerMenuTimeout);

			thisElement.mouseout(function () {
				clearTimeout(menuTimeoutOpen2);
			});
		});

		// Odebrání aktivní varianty při sjetí z položky menu
		$('.s31-menu-pointLink').mouseout(function () {
			var thisElement = $(this);

			var menuTimeoutCLose2 = setTimeout(function () {
				thisElement.removeClass('s31-menu-hover');
			}, headerMenuTimeout);

			thisElement.mouseover(function () {
				clearTimeout(menuTimeoutCLose2);
			});
		});

		// Skrytí stínu a menu při najetí na header
		$('header').mouseover(function () {
			var thisElement = $(this);

			var menuTimeoutCLose3 = setTimeout(function () {
				$('.s31-sub-menu-container-shadow:not(.s31-openedByHead)').hide();
				$('div[id^="s31-submenu-container-"],div[id*=" s31-submenu-container-"]').hide();
				$('div[id^="s31-submenu-button-"],div[id*=" s31-submenu-button-"]').removeClass('s31-menu-hover');
			}, headerMenuTimeout);

			thisElement.mouseover(function () {
				clearTimeout(menuTimeoutCLose3);
			});
		});

	} else if (mqMENUtablet.matches) {

		// Zobrazení stínu pod menu a otevřených položek v hlavičce při najetí na submenu
		$('div[id^="s31-submenu-button-"] .s31-menu-pointPrevent').on("click", function (e) {
			var thisElement = $(this).parent();
			var menuId = thisElement.attr("data-menuId");
			e.preventDefault();

				$('#criteria_search_value').typeahead('close');
				$('.s31-search-button').removeClass("s31-search-focus");
				$('.s31-search-input:not(.tt-hint)').removeClass("s31-search-focus");
				$('div[id^="s31-submenu-button-"],div[id*=" s31-submenu-button-"]').removeClass('s31-menu-hover');
				$('div[id^="s31-submenu-container-"],div[id*=" s31-submenu-container-"]').hide();
				$('.s31-sub-menu-container-shadow').show();
				$('#s31-submenu-container-' + menuId).show();
				$('.s31-sub-menu-container-shadow').removeClass('s31-openedByHead');
				thisElement.addClass('s31-menu-hover');
				$('#account-layer').slideUp(headerAnimationDuration);
				$('#s31-cart-layer .s31-cart-layer-inside').slideUp(headerAnimationDuration);
				$('#account-button').removeClass('s31-activeBlock');
				$('#cart-button').removeClass('s31-activeBlock');

		});

		// Skrytí stínu pod menu a otevřených položek v hlavičce při kliknutí na menu
		$('.s31-menu-pointLink').on("click", function () {
			var thisElement = $(this);

			$('#criteria_search_value').typeahead('close');
			$('.s31-search-button').removeClass("s31-search-focus");
			$('.s31-search-input:not(.tt-hint)').removeClass("s31-search-focus");
			$('div[id^="s31-submenu-button-"],div[id*=" s31-submenu-button-"]').removeClass('s31-menu-hover');
			thisElement.addClass('s31-menu-hover');
			$('#account-layer').slideUp(headerAnimationDuration);
			$('#s31-cart-layer .s31-cart-layer-inside').slideUp(headerAnimationDuration);
			$('#account-button').removeClass('s31-activeBlock');
			$('#cart-button').removeClass('s31-activeBlock');
			$('.s31-sub-menu-container-shadow:not(.s31-openedByHead)').hide();

		});

	}

	// Ostatní JS společné pro všechny zařízení
	//----------------------------------------------------------------------

});