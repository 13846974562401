// const handleProductOptionsChange = function handleProductOptionsChange() {
//     $('[name*="sylius_add_to_cart[cartItem][variant]"]').on('change', () => {
//         let selector_price = '';
//         let selector_stock = '';
//         let selector_code = '';
//
//         const option = $('#sylius-product-adding-to-cart input:radio:checked').val();
//         const option_stock = option;//.replace('VELIKOST_', '')
//         let option_text = $('#sylius-product-adding-to-cart input:radio:checked').next().html();
//
//         selector_price = `[data-velikost="${option}"]`;
//         selector_stock = `[data-stock="${option_stock}"]`;
//         selector_code = `[data-code="${option}"]`;
//
//         let price = $('#sylius-variants-pricing').find(selector_price).attr('data-value');
//         let price_original = $('#sylius-variants-stocks').find(selector_stock).attr('data-originalprice');
//         let stock = $('#sylius-variants-stocks').find(selector_stock).attr('data-value');
//         let code = $('#sylius-variants-stocks').find(selector_code).attr('data-value');
//         let order_code = $('#sylius-variants-stocks').find(selector_code).attr('data-variant-code');
//         let ean = $('#sylius-variants-stocks').find(selector_stock).attr('data-ean');
//         let variant_code = $('#sylius-variants-stocks').find(selector_code).attr('data-variant-code');
//         let availability_code = $('#sylius-variants-stocks').find(selector_stock).attr('data-availability');
//         let reduce = $('#sylius-variants-stocks').find(selector_stock).attr('data-reduce');
//         var price_without_vat = $('#sylius-variants-stocks').find(selector_stock).attr('data-price-without-vat');
//         var price_without_vat_mo = $('#sylius-variants-stocks').find(selector_stock).attr('data-price-without-vat-mo');
//
//         if (typeof availability_code === 'undefined') {
//             availability_code = stock > 0 ? 'A' : 'V';
//         }
//
//         $('.s31-article-attributes').hide();
//         $('.s31-article-attributes[data-variant-code='+variant_code+']').show();
//
//         $('#sylius_product_watchdog_itemId').val(variant_code);
//
//         $('#s31-selectedSize').html(option_text);
//         $('.s31-instock-info .s31-article-stock').html(eval("detail_pageCode_" + availability_code));
//
//         $(".s31-tocart-block").removeClass("s31-in-stock").removeClass("s31-not-in-stock");
//         $(".s31-instock-info .s31-article-stock").removeClass("s31-instock").removeClass("s31-nostock").removeClass("s31-laterstock");
//         $(".s31-deliveryText").hide();
//
//         if ((availability_code === "A") && stock > 0) {
//             $(".s31-tocart-block").addClass("s31-in-stock");
//             $(".s31-instock-info .s31-article-stock").addClass("s31-instock");
//             $(".s31-article-darkBlock").show();
//             $(".s31-article-watchdogBlock").hide();
//             $(".s31shop-watchdog-validation-error").addClass("d-none");
//             $("#s31-deliveryText_" + availability_code).show();
//             if (stock < 11) {
//                 $(".s31-instock-info .s31-article-stock").html(productAvailable + " " + stock + " " + piece);
//             } else {
//                 $(".s31-instock-info .s31-article-stock").html(productAvailableMore);
//             }
//         } else if ((availability_code === "A") && stock == 0) {
//             $(".s31-instock-info .s31-article-stock").addClass("s31-laterstock");
//             $(".s31-article-darkBlock").show();
//             $(".s31-article-watchdogBlock").hide();
//             $(".s31shop-watchdog-validation-error").addClass("d-none");
//             $('.s31-instock-info .s31-article-stock').html(eval("detail_pageCode_M"));
//             $("#s31-deliveryText_empty" ).show();
//         } else if (availability_code === "P") {
//             $(".s31-tocart-block").addClass("s31-in-stock");
//             $(".s31-article-darkBlock").show();
//             $(".s31-instock-info .s31-article-stock").addClass("s31-instock");
//         } else if (availability_code === "V" || availability_code === "H") {
//             $(".s31-tocart-block").addClass("s31-not-in-stock");
//             $(".s31-instock-info .s31-article-stock").addClass("s31-nostock");
//             $(".s31-article-darkBlock").hide();
//             $(".s31-article-watchdogBlock").show();
//             $("#s31-deliveryText_empty").show();
//         } else if (availability_code === 'C') {
//             $(".s31-instock-info .s31-article-stock").addClass("s31-laterstock");
//             $(".s31-article-darkBlock").hide();
//             $(".s31-article-watchdogBlock").show();
//             $("#s31-deliveryText_empty" ).show();
//         } else if (availability_code === 'D') {
//             $(".s31-instock-info .s31-article-stock").addClass("s31-laterstock");
//             $(".s31-article-darkBlock").hide();
//             $(".s31-article-watchdogBlock").show();
//             $("#s31-deliveryText_empty" ).show();
//         } else if (availability_code === 'E') {
//             $(".s31-instock-info .s31-article-stock").addClass("s31-laterstock");
//             $(".s31-article-darkBlock").show();
//             $(".s31-article-watchdogBlock").hide();
//             $("#s31-deliveryText_E" ).show();
//         } else if (availability_code === 'B') {
//             $(".s31-instock-info .s31-article-stock").addClass("s31-instock");
//             $(".s31-article-darkBlock").show();
//             $(".s31-article-watchdogBlock").hide();
//             $("#s31-deliveryText_B" ).show();
//         } else if (stock > 0) {
//             $(".s31-tocart-block").addClass("s31-in-stock");
//             $(".s31-instock-info .s31-article-stock").addClass("s31-instock");
//             $(".s31-article-darkBlock").show();
//             $(".s31-article-watchdogBlock").hide();
//             $(".s31shop-watchdog-validation-error").addClass("d-none");
//             $("#s31-deliveryText_A").show();
//             if (stock < 11) {
//                 $(".s31-instock-info .s31-article-stock").html(productAvailable + " " + stock + " " + piece);
//             } else {
//                 $(".s31-instock-info .s31-article-stock").html(productAvailableMore);
//             }
//         } else {
//             $(".s31-instock-info .s31-article-stock").addClass("s31-nostock");
//             $(".s31-article-darkBlock").hide();
//             $(".s31-article-watchdogBlock").show();
//             $("#s31-deliveryText_empty" ).show();
//         }
//
//         if (price !== undefined && stock !== undefined && stock > 0) {
//             $('#product-price').text(price);
//             $('#product-price-without-tax').text(price_without_vat);
//             $('#product-price-without-tax-mo').text(price_without_vat_mo);
//             $('.product-code-cont').text(code);
//             $('.product-code-vo-cont').text(variant_code);
//             $('#product-code-info').text(code);
//             $('#visionPigeon').attr('data-section-id', code);
//             if (ean !== undefined) {
//                 $('#s31-article-ean').show();
//                 $('#product-ean-info').text(ean);
//             } else {
//                 $('#s31-article-ean').hide();
//             }
//             if(price_original !== undefined && price_original != 0) {
//                 $('#product-original-price').show().text(price_original);
//                 if (reduce != "-0%") {
//                     $('#s31-article-price-percent').show().text(reduce);
//                 }
//             } else {
//                 $('#product-original-price').hide().text("");
//                 $('#s31-article-price-percent').hide().text("");
//             //$('button[type=submit]').removeAttr('disabled');
//             }
//         } else {
//             $('#product-price').text(price);
//             $('#product-price-without-tax').text(price_without_vat);
//             $('#product-price-without-tax-mo').text(price_without_vat_mo);
//             $('.product-code-cont').text(code);
//             $('.product-code-vo-cont').text(variant_code);
//             $('#product-code-info').text(code);
//             $('#visionPigeon').attr('data-section-id', code);
//             if (ean !== undefined) {
//                 $('#s31-article-ean').show();
//                 $('#product-ean-info').text(ean);
//             } else {
//                 $('#s31-article-ean').hide();
//             }
//             if(price_original !== undefined && price_original != 0) {
//                 $('#product-original-price').show().text(price_original);
//                 if (reduce != "-0%") {
//                     $('#s31-article-price-percent').show().text(reduce);
//                 }
//             } else {
//                 $('#product-original-price').hide().text("");
//                 $('#s31-article-price-percent').hide().text("");
//             //$('button[type=submit]').attr('disabled', 'disabled');
//             }
//         }
//
//         let app_detail_variant_change_event = new CustomEvent('app_detail_variant_change_event',
//             {
//                 'detail': {
//                     'variantCode': variant_code,
//                     'orderCode': code
//                 }
//             }
//         );
//         document.dispatchEvent(app_detail_variant_change_event);
//     });
// };
//
// $.fn.extend({
//     variantPrices() {
//         if ($('#sylius-variants-pricing').length > 0) {
//             handleProductOptionsChange();
//         }
//     },
// });
//
// $(document).variantPrices();
//
// if($('#sylius_add_to_cart_cartItem_variant_VELIKOST').length !== 0) {
//     $('#sylius_add_to_cart_cartItem_variant_VELIKOST .s31-checkbox-radio>div').each(function () {
//         var radio_value = $(this).find("input[type='radio']").val();
//         var radio_size = radio_value;//.replace('VELIKOST_', '');
//         var data_stock = `[data-stock="${radio_size}"]`;
//
//         var stock_variant = $('#sylius-variants-stocks').find(data_stock).attr('data-value');
//
//         $(this).removeClass("s31-in-stock").removeClass("s31-not-in-stock");
//         if (stock_variant !== undefined && stock_variant > 0) {
//             $(this).addClass("s31-in-stock");
//         }
//         else{
//             $(this).addClass("s31-not-in-stock");
//         }
//     });
// }
//
//
// if ($('.s31-wishlist-select').length > 0) {
//     $('[name*="sylius_add_to_cart[cartItem][variant]"]').on("change", function () {
//         var product_id = $(this).closest('form').data('productid');
//         var option = $(this).val();
//         var data_price = `[data-velikost="${option}"]`;
//         var variant_price = $('#sylius-variants-pricing-'+product_id).find(data_price).attr('data-value');
//         var data_stock = `[data-stock="${option}"]`;
//         var stock = $('#sylius-variants-stocks-'+product_id).find(data_stock).attr('data-value');
//         var price_original = $('#sylius-variants-stocks-'+product_id).find(data_stock).attr('data-originalprice');
//
//         if (variant_price !== undefined && stock !== undefined && stock > 0) {
//             $('#s31-article-box-'+product_id+' .s31-article-price').text(variant_price);
//             if(price_original !== undefined && price_original != 0){
//                 $('#s31-article-box-'+product_id+' .s31-article-price-original').text(price_original);
//             }
//             else {
//                 $('#s31-article-box-' + product_id + ' .s31-article-price-original').text('');
//             }
//             $('#s31-article-box-'+product_id+' .s31-article-stock').removeClass("s31-nostock").addClass("s31-instock").text(productAvailable);
//         }
//         else{
//             $('#s31-article-box-'+product_id+' .s31-article-price').text(variant_price);
//             if(price_original !== undefined && price_original != 0){
//                 $('#s31-article-box-'+product_id+' .s31-article-price-original').text(price_original);
//             }
//             else {
//                 $('#s31-article-box-' + product_id + ' .s31-article-price-original').text('');
//             }
//             $('#s31-article-box-'+product_id+' .s31-article-stock').removeClass("s31-instock").addClass("s31-nostock").text(productNotAvailable);
//         }
//     });
// }
//
$(function () {
    MagicScroll.refresh();

    var galleryImagesLoaded = 0;
    var totalGalleryImages = $(".s31-article-gallery img").length;

    $(".s31-article-gallery img").on("load", function (event) {
        galleryImagesLoaded++;

        if (galleryImagesLoaded == totalGalleryImages) {
            MagicScroll.refresh();
        }
    });
});

document.addEventListener('app_detail_variant_change_event', function (e) {
    let payload = e.detail;
    payload['event'] = 'productDetailVariantChange';

    if (typeof dataLayer !== 'undefined') {
        dataLayer.push(payload);
    }
});
//
//
//
