/**
 * Javascript pro @S31ShopWishlistBundle
 */

/**
 * Definice událostí
 */

// Definice konstat událostí wishlistu
const s31shop_wishlist_event = {
    // Událost přidání do wishlistu
    'added': 's31shop_wishlist_event_added',
    // Událost odebraní z wishlistu
    'removed': 's31shop_wishlist_event_removed'
};

// Přidání do wishlistu
var s31shop_wishlist_event_added = {};
//  Odebraní z wishlistu
var s31shop_wishlist_event_removed = {};

/**
 * Objekt pro obsluhu wishlistu
 * @type {{add: s31shop_wishlist.add, delete: s31shop_wishlist.delete}}
 */
var s31shop_wishlist = {
    /**
     * Přídává variantu zboží do wishlistu
     * @param route
     */
    handle: undefined,
    add: function (route) {
        var handle = this.handle;
        $.ajax({
            url: route,
            success: function (payload) {
                if (payload.status === 1) {
                    s31shop_wishlist_event_added = new CustomEvent(s31shop_wishlist_event.added, {'detail':{'handle': handle}});
                    document.dispatchEvent(s31shop_wishlist_event_added);
                }
            }
        });
    },
    /**
     * Maže položku z wishlistu
     * @param route
     */
    remove: function (route) {
        var handle = this.handle;
        $.ajax({
            url: route,
            success: function (payload) {
                if (payload.status === 1) {
                    s31shop_wishlist_event_removed = new CustomEvent(s31shop_wishlist_event.removed, {'detail':{'handle': handle}});
                    document.dispatchEvent(s31shop_wishlist_event_removed);
                }
            }
        });
    }
};

$(document).on('click', '.s31-wishlist-button', function(e) {
    e.preventDefault();

    var route = $(this).attr('href');
    var action = $(this).data('action');

    if (action === 'add') {
        // pridani do wishlistu
        s31shop_wishlist.handle = this;
        s31shop_wishlist.add(route);
    } else {
        // odebrani z wishlistu
        s31shop_wishlist.handle = this;
        s31shop_wishlist.remove(route);
    }
});

$(".s31-article-toCartButt select").on("click", function (e) {
    e.preventDefault();
});

/**
 * Výměna akce a textu pro provedeném přidání/odebrání
 */
document.addEventListener(s31shop_wishlist_event.added, function(e) {
    var handle = $(e.detail.handle);
	
	handle.next(".s31-wishlist-confirmation").addClass("s31-wishlist-finishAnimation");
	handle.next(".s31-wishlist-confirmation").css("display","block");
    handle.next(".s31-wishlist-confirmation").addClass("s31-wishlist-finishAnimationOverlay");
    $(".s31-wishlist-header-button .s31-ico").addClass("active");

	setTimeout(function(){
		handle.next(".s31-wishlist-confirmation").removeClass("s31-wishlist-finishAnimationOverlay");
		handle.next(".s31-wishlist-confirmation").css("display","none");
    }, 4000);
	
	setTimeout(function(){
		if (currentNumberOfWishlistItems == 0) {
			$("#s31-wishist-headerCount").fadeIn(100);
		}
		$("#s31-wishist-headerCount").html(currentNumberOfWishlistItems + 1);
		currentNumberOfWishlistItems = currentNumberOfWishlistItems + 1;
    }, 200);

	handle.data('action', 'remove');
    ajaxData = handle.data('ajax-data');

    handle.attr('href', ajaxData['_remove_action']);
    handle.removeClass('s31-wishlist-add');
    handle.addClass('s31-wishlist-remove');
    handle.attr('title', ajaxData['_remove_text']);
    setTimeout(function(){
        handle.next(".s31-wishlist-confirmation").find("p").html(ajaxData['_removed_text']);
    }, 4000);
});

document.addEventListener(s31shop_wishlist_event.removed, function(e) {
    var handle = $(e.detail.handle);
	
	handle.next(".s31-wishlist-confirmation").addClass("s31-wishlist-finishAnimation");
	handle.next(".s31-wishlist-confirmation").css("display","block");
    handle.next(".s31-wishlist-confirmation").addClass("s31-wishlist-finishAnimationOverlay");
    
	setTimeout(function(){
		handle.next(".s31-wishlist-confirmation").removeClass("s31-wishlist-finishAnimationOverlay");
		handle.next(".s31-wishlist-confirmation").css("display","none");
    }, 4000);
	
	$( "#s31-wishlist-animationObject" ).removeClass("s31-wishlist-finishAnimation");
	if (currentNumberOfWishlistItems == 1) {
        $("#s31-wishist-headerCount").fadeOut(100);
        $(".s31-wishlist-header-button .s31-ico").removeClass("active");
    }

	$("#s31-wishist-headerCount").html(currentNumberOfWishlistItems - 1);
	currentNumberOfWishlistItems = currentNumberOfWishlistItems - 1;

    handle.data('action', 'add');
    ajaxData = handle.data('ajax-data');
	
    handle.attr('href', ajaxData['_add_action']);
    handle.removeClass('s31-wishlist-remove');
    handle.addClass('s31-wishlist-add');
    handle.attr('title', ajaxData['_add_text']);
    setTimeout(function(){
        handle.next(".s31-wishlist-confirmation").find("p").html(ajaxData['_added_text']);
    }, 4000);
});