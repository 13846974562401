(function ($) {
	'use strict';

	$.fn.extend({
		removeFromCart: function (selector) {
			var elements = $(this);

			if (typeof selector === "undefined") {
				elements.each(function (index, element) {
					var element = $(element);

					element.on("click", function (e) {
						e.preventDefault();
						e.stopPropagation();

						deleteFunction(this);

						return false;
					});
				});
			} else {
				$(document).on("click", selector, function (e) {
					e.preventDefault();
					e.stopPropagation();

					deleteFunction(this);

					return false;
				});
			}
		}
	});

	var activeRequest = false;

	var deleteFunction = function (element) {
		var redirectUrl = $(element).data('redirect');
		var csrfToken = $(element).data('csrf-token');
		var href = $(element).data("url");

		$(element).parents(".s31-cart-layer-insideItem").remove();

		if (activeRequest) {
			return;
		}

		activeRequest = true;

		$.ajax({
			method: 'DELETE',
			data: {"_csrf_token": csrfToken},
			cache: false,
			url: href,
			success: function (response) {
				var cartLayerVisible = $("#cart-button .s31-cart-layer-inside").is(":visible"),
						mobileCartLayerVisible = $("#s31-cart-layer-mobile").is(":visible");

				if (redirectUrl) {
					window.location.replace(redirectUrl);

					return;
				}

				if (response.hasOwnProperty("cartLayer")) {
					var $cartLayer = $(response.cartLayer);

					if (cartLayerVisible) {
						var scrollTop = $("#cart-button .s31-cart-layer-inside .s31-cart-layer-insideItems").scrollTop();

						$cartLayer.find(".s31-cart-layer-widget").addClass("s31-activeBlock");
						$cartLayer.find(".s31-cart-layer-inside").css("display", "block");
					}

					if (mobileCartLayerVisible) {
						var scrollTop = $("#s31-cart-layer-mobile .s31-cart-layer-inside .s31-cart-layer-insideItems").scrollTop();
					}

					$(".s31-cart-layer-widget").html($cartLayer);

					if (cartLayerVisible) {
						$("#cart-button .s31-cart-layer-inside .s31-cart-layer-insideItems").scrollTop(scrollTop);
					}

					if (mobileCartLayerVisible) {
						$("#s31-cart-layer-mobile .s31-cart-layer-inside .s31-cart-layer-insideItems").scrollTop(scrollTop);
					}
				}
			},
			complete: function() {
				activeRequest = false;
			}
		});
	}

})(jQuery);
