function loadBanners(position, limit) {
    if (Array.isArray(position)) {
        position = position.join("-");
    }

    $.get("https://tbs.astoreo.cz/api/position/" + position, function(payloads) {
        for (var i in payloads) {
            var payload = payloads[i];

            var bannerHtml = "";
            var anyBannerAdded = false;
            var isSlider = false;
            if (payload.hasOwnProperty("slider") && payload.slider == true) {
                isSlider = true;
            }

            if (isSlider) {
                bannerHtml = '<div class="s31-slider-bigWrapper"><div class="swiper-container s31-slider-hp"><div class="swiper-wrapper">';
            }

            for (var ii in payload.banners) {
                var banner = payload.banners[ii];
                
                if (banner.limitation > '') {
                    var limitation = banner.limitation.split(",");
                    if (typeof limit !== "undefined" && limitation.indexOf(limit) == -1) {
                        continue;
                    }
                }

                if (isSlider) {
                    bannerHtml += '<div class="swiper-slide s31-showDescriptionCont">';
                }

                if (banner.url != "" && banner.url != null) {
                    bannerHtml += '<a href="' + banner.url + '"';
                    if (banner.openInNewWindow) {
                        bannerHtml += ' target="_blank"';
                    }

                    bannerHtml += '>';
                }

                if ((banner.html == "" || banner.html == null) && (banner.image != "" && banner.image != null)) {
                    var bannerAlt = "";
                    if (banner.alt != "" && banner.alt != null) {
                        bannerAlt = banner.alt;
                    }
                    bannerHtml += '<img class="s31-bitbag-image" src="' + banner.image + '" alt="' + bannerAlt + '">';
                } else {
                    bannerHtml += banner.html;
                }

                if (banner.url != "" && banner.url != null) {
                    bannerHtml += '</a>';
                }

                if (isSlider) {
                    bannerHtml += "</div>";
                }

                anyBannerAdded = true;
            }

            if (isSlider) {
                bannerHtml += '</div></div><div class="swiper-button-prev s31-slider-hp-prev">&nbsp;</div><div class="swiper-button-next s31-slider-hp-next">&nbsp;</div></div>';
            }

            if (anyBannerAdded) {
                $("#banner-position-" + payload.id).append(bannerHtml);
                $("#banner-position-" + payload.id).slideDown();
            }
        }
    });
}