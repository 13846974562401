if ($('#s31-action-timer').length > 0) {
    var countDownDate = new Date(countDownDateRaw).getTime();

    var actionTimer = setInterval(function() {

        var now = new Date().getTime();

        var distance = countDownDate - now;

        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        var sHours = hours.toString().split("");
        var sMinutes = minutes.toString().split("");
        var sSeconds = seconds.toString().split("");
        

        var hoursText = "";
        var minutesText = "";
        var secondsText = "";

        if (sHours.length < 2) {
            hoursText = "<span>0</span>";
        }
        for (var i = 0; i < sHours.length; i++) {
            hoursText += "<span>" + sHours[i] + "</span>";
        }

        if (sMinutes.length < 2) {
            minutesText = "<span>0</span>";
        }
        for (var i = 0; i < sMinutes.length; i++) {
            minutesText += "<span>" + sMinutes[i] + "</span>";
        }

        if (sSeconds.length < 2) {
            secondsText = "<span>0</span>";
        }
        for (var i = 0; i < sSeconds.length; i++) {
            secondsText += "<span>" + sSeconds[i] + "</span>";
        }

        document.getElementById("s31-action-timer").innerHTML = "<div><span>" + days + "</span></div>:" + "<div>" + hoursText + "</div>:" + "<div>" + minutesText + "</div>:" + "<div>" + secondsText + "</div>";

        if (distance < 0) {
            clearInterval(actionTimer);
            document.getElementById("s31-action-banner").style.display = 'none';
        }
    }, 1000);
}